import React, { Component } from "react";
import { StatisticsTable } from "../../style/statisticsByPubAndOfferTable";
import DataTable from "react-data-table-component";
import i18next from "i18next";

export default class ConversionStatisticsOffer extends Component {
    rowClicked = (rowData) => {
        const offerID = rowData.offer_id;
        // const recentOfferFilter=this.props.offerFilter;
        // if (recentOfferFilter.filter(e=>e.value===offerID).length >0){
        //     return false;
        // }
        // recentOfferFilter.push({
        //     value: offerID,
        //     label: offerID
        // });
        this.props.handleClick("offerId", [{ value: offerID, label: offerID }], ".select-offer");
    };
    render() {
        const columns = [
            {
                name: i18next.t("offer_id"),
                selector: "offer_id",
                maxWidth: "70px",
                sortable: true,
                center: true,
            },
            {
                name: i18next.t("conversion"),
                selector: "conversion_text",
                sortable: true,
                center: true,
            },
            {
                name: i18next.t("ar"),
                selector: "ar",
                sortable: true,
                center: true,
            },
        ];
        return (
            <div className="col-12 col-md-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">{i18next.t("offer_statistics")}</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={this.props.synthesizedConversions}
                    columns={columns}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={StatisticsTable}
                    noHeader={true}
                    defaultSortField="name"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    onRowClicked={this.rowClicked}
                />
            </div>
        );
    }
}
