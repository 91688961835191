import React, {Component} from "react";
import Select from "react-select";
import Dropdown from "react-dropdown";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import axios from "axios";
import moment from "moment";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/daterangepicker.css";
import {selectTheme} from "../style/reactselect";

import Main from "./base/Main";
import SummaryStatistics from "./base/SummaryStatistics";
import ConversionList from "./base/ConversionList";
import redirectNoToken from "../helper/redirectNoToken";
import {data} from "jquery";
import ConversionStatisticsOffer from "./base/ConversionStatisticsOffer";
import ApprovedComboStatistics from "./base/ApprovedComboStatistics";
import ConversionsStatisticsTwoHours from "./base/ConversionsStatisticsTwoHours";

import {moneyFormat} from "../helper/helper";
import i18next from "i18next";

export default class Statistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerIdOptions: [
                {value: "", label: "Offer ID"},
                {value: "one", label: "One"},
                {value: "two", label: "Two"},
            ],
            offerId: [],
            status: "",
            date: [new Date(), new Date()],
            conversionsList: [],
            conversions: [],
            isLoadingTable: true,
            approved: "0",
            pending: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            pendingPayout: 0,
            approvedPayout: 0,
            rejectedPayout: 0,
            allConversions: 0,
            quickFilter: {value: "today", label: i18next.t("today")},
            dateType: {label: i18next.t("created_date"), value: "false"},
            deliveryStatusStats: "0/0/0",
        };
    }

    componentDidMount() {
        document.title = "Statistics";
        redirectNoToken.bind(this)();
        this.getConversionList();
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getConversionList = () => {
        this.signal = axios.CancelToken.source();
        var from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        var to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/conversions?from_date=${from_date}&to_date=${to_date}&modified_date=${this.state.dateType.value}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => ({
                    order_id: v.order_id,
                    created_date: new Date(v.created_date).toLocaleString(),
                    modified_date: new Date(v.modified_date).toLocaleString(),
                    created_date_unmodified: v.created_date,
                    offer: v.offer.alias,
                    category: v.offer.category.name,
                    status: v.status,
                    modify_count: v.modify_count,
                    name: v.name,
                    phone: v.phone,
                    message: v.message,
                    affsub1: v.aff_sub1,
                    affsub2: v.aff_sub2,
                    affsub3: v.aff_sub3,
                    affsub4: v.aff_sub4,
                    sale_message: v.sale_message,
                    address: v.address,
                    payoutPub: v.payoutPub,
                    quantity: v.quantity,
                    isCombo: v.offer.isCombo,
                    comboLog: v.comboLog,
                    comboAlias: (v.comboLog) ? v.comboLog.alias : "",
                    currency: (v.offer.currency) ? v.offer.currency : "",
                    delivery_status: v.delivery_status ? v.delivery_status : "", 
                    delivery_code: v.delivery_code ? v.delivery_code : "", 
                }));
                var offers = response.data.map((offer) => {
                    return offer.offer.alias;
                });
                offers = [...new Set(offers)].map((value) => {
                    return {value: value, label: value};
                });
                offers = offers.reverse();
                this.setState(
                    {
                        conversions: data,
                        conversionsList: data,
                        isLoadingTable: false,
                        offerIdOptions: offers,
                    },
                    () => this.filterConversion()
                );
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    handleChangeStatisticsTable = (name, value, select) => {
        this.setState({[name]: value}, () => {
            this.triggerMouseEvent(document.querySelector(select + " .devas-select__control"), "mousedown");
            this.filterConversion();
        });
    };
    triggerMouseEvent = (node, eventType) => {
        var clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent(eventType, true, true);
        node.dispatchEvent(clickEvent);
    };
    synthesizeConversionByOffer = (conversions) => {
        let synthesizedConversions = {};
        conversions.forEach(function (item, index) {
            if (!(item.offer in synthesizedConversions)) {
                synthesizedConversions[item.offer] = {
                    total: 0,
                    approved: 0,
                    rejected: 0,
                    pending: 0,
                };
            }
            synthesizedConversions[item.offer]["total"]++;
            synthesizedConversions[item.offer]["name"] = item.name;
            synthesizedConversions[item.offer]["offer_id"] = item.offer;
            if (item.status === "approved") synthesizedConversions[item.offer]["approved"]++;
            else if (item.status === "customer_reject") {
                synthesizedConversions[item.offer]["rejected"]++;
            } else if (item.status === "new" || item.status === "callbacks") {
                synthesizedConversions[item.offer]["pending"]++;
            }
            synthesizedConversions[item.offer]["conversion_text"] =
                synthesizedConversions[item.offer]["approved"] +
                "/" +
                synthesizedConversions[item.offer]["pending"] +
                "/" +
                synthesizedConversions[item.offer]["rejected"] +
                "/" +
                synthesizedConversions[item.offer]["total"];
            let ar = (synthesizedConversions[item.offer]["approved"] / (synthesizedConversions[item.offer]["approved"] + synthesizedConversions[item.offer]["pending"] + synthesizedConversions[item.offer]["rejected"]));
            synthesizedConversions[item.offer]["ar"] = Number.isNaN(ar) ? 0 + "%" : (ar * 100).toFixed(2) + "%";
        });
        return Object.values(synthesizedConversions);
    };
    synthesizeApprovedComboConversions = (conversions) => {
        let comboConversions = conversions.filter(v => (v.isCombo && v.status === "approved" && v.comboLog && v.comboLog.hasOwnProperty("alias")));
        let synthesizedConversions = {}
        comboConversions.forEach((item, index) => {
            if (!((item.offer + item.comboLog.alias) in synthesizedConversions)) {
                synthesizedConversions[item.offer + item.comboLog.alias] = {
                    payoutPub: 0,
                    total: 0,
                    price: 0
                }
            }
            synthesizedConversions[item.offer + item.comboLog.alias]["offer"] = item.offer;
            synthesizedConversions[item.offer + item.comboLog.alias]["currency"] = item.currency;
            synthesizedConversions[item.offer + item.comboLog.alias]["price"] += parseInt(item.comboLog.price);
            synthesizedConversions[item.offer + item.comboLog.alias]["combo"] = item.comboLog.alias;
            synthesizedConversions[item.offer + item.comboLog.alias]["payoutPub"] += parseInt(item.comboLog.payoutPub);
            synthesizedConversions[item.offer + item.comboLog.alias]["total"] += 1;

        });
        let objSynthesizedConversions = [...Object.values(synthesizedConversions)];
        objSynthesizedConversions.map((v) => {
            v.payoutPub = "$" + moneyFormat(parseFloat(v.payoutPub), 0, 3);
            v.price = moneyFormat(parseFloat(v.price), 0, 3) + v.currency;
        })
        return Object.values(objSynthesizedConversions);
    }
    calculateSummary = (data) => {
        let approved = data.filter((v) => v.status === "approved").length;
        let new_conv = data.filter((v) => v.status === "new").length;
        let callbacks = data.filter((v) => v.status === "callbacks").length;
        let customer_reject = data.filter((v) => v.status === "customer_reject").length;
        let trash = data.filter((v) => v.status === "trash").length;
        let duplicated = data.filter((v) => v.status === "duplicated").length;
        let conversionsTotal = data.length - trash - duplicated === 0 ? 1 : data.length - trash - duplicated;
        let approved_rate = ((approved / conversionsTotal) * 100).toFixed(2);
        let allConversions = data.length;
        let approvedPayout = 0;
        let pendingPayout = 0;
        let rejectedPayout = 0;
        data.forEach((v) => {
            if (v.status === "approved") approvedPayout += v.payoutPub;
            else if (v.status === "new" || v.status === "callbacks") pendingPayout += v.payoutPub;
            else rejectedPayout += v.payoutPub;
        });
        let deliveryStatusStats = (data) => {
            let shipped = 0;
            let delivered = 0;
            let returned = 0;
            data.forEach((v) => {
                if (v.delivery_status === "shipped") {
                    shipped += 1;
                }
                if (v.delivery_status === "delivered") {
                    delivered += 1;
                }
                if (v.delivery_status === "returned") {
                    returned += 1;
                }
            });
            return shipped + "/" + delivered + "/" + returned;
        };
        this.setState({
            approved,
            new_conv,
            callbacks,
            customer_reject,
            trash,
            duplicated,
            approved_rate,
            allConversions,
            approvedPayout,
            pendingPayout,
            rejectedPayout,
            deliveryStatusStats: deliveryStatusStats(data),
        });
    };
    handleChangeOfferId = (offerId) => {
        this.setState({offerId}, () => this.filterConversion());
    };
    handleChangeDateType = (dateType) => {
        this.setState({dateType}, () => this.getConversionList());
    };
    resetState = () => {
        this.setState({
            conversionsList: [],
            conversions: [],
            approved: "0",
            pending: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            pendingPayout: 0,
            approvedPayout: 0,
            rejectedPayout: 0,
            allConversions: 0,
            dateType: {label: "Created Date", value: "false"},
        });
    };
    handleDropDown = (e) => {
        this.setState({status: e.value}, () => this.filterConversion());
    };
    onChange = (date) =>
        this.setState({date}, () => {
            if (this.state.date && this.state.date[0] && this.state.date[1]) {
                this.getConversionList();
            } else if (this.state.date == null) {
                this.resetState();
            }
        });
    filterConversion = () => {
        this.setState((state, props) => {
            let conversions = state.conversionsList.filter((conversion) => {
                return (
                    conversion.status.includes(state.status) &&
                    (state.offerId === null || state.offerId.length === 0 || this.checkOfferInList(conversion.offer))
                );

            });
            this.calculateSummary(conversions);
            return {conversions};
        });
    };
    checkOfferInList = (offerId) => {
        return this.state.offerId.some((v) => {
            return offerId == v.value;
        });
    };
    reloadConversions = () => {
        this.setState({conversionsList: [], conversions: [], isLoadingTable: true}, () => this.getConversionList());
    };
    handleDropDownQuickFilter = (e) => {
        let date = [];
        switch (e.value) {
            case "today":
                date = [new Date(), new Date()];
                break;
            case "yesterday":
                date = [new Date() - 86400000, new Date() - 86400000];
                break;
            case "last7days":
                date = [new Date() - 604800000, new Date()];
                break;
            case "last14days":
                date = [new Date() - 1209600000, new Date()];
                break;
            case "lastMonth":
                date = [new Date() - 2592000000, new Date()];
                break;
            default:
            // code block
        }
        this.setState({status: "", offerId: [], date, quickFilter: e}, () => this.getConversionList());
    };
    synthesizeConversionsEveryTwoHours = (conversions) => {
        let data = [];
        for (let i = 2; i <= 24; i += 2) {
            data.push({
                y: conversions.filter((conversion) => {
                    return (moment(conversion.created_date_unmodified).hour() >= i - 2 && moment(conversion.created_date_unmodified).hour() < i);
                }).length,
                label: (i - 2).toString() + "-" + i.toString(),

            })
        }
        return data;
    }

    render() {
        var {
            offerIdOptions,
            status,
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            pendingPayout,
            rejectedPayout,
            approvedPayout,
            allConversions,
            quickFilter,
            dateType,
            offerId,
            deliveryStatusStats,
        } = this.state;
        return (
            <Main history={this.props.history} linkTo={["/statistics"]} linkName={[i18next.t("statistics")]}
                  selectedSection={2}>
                <div className="col-md-9 col-12">
                    <div className="row">
                        <div className="d-lg-block col-md-8 col-6"></div>
                        <div className="col-md-4 col-6 py-3">
                            <Select
                                isMulti={false}
                                options={[
                                    {label: i18next.t("created_date"), value: "false"},
                                    {label: i18next.t("modified_date"), value: "true"},
                                ]}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={this.handleChangeDateType}
                                theme={selectTheme}
                                value={dateType}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-6 col-lg-3 pb-3">
                    <Select
                        defaultValue={""}
                        isMulti
                        name="offerId"
                        options={offerIdOptions}
                        placeholder={i18next.t("offer_id")}
                        className="devas-select select-offer"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeOfferId}
                        theme={selectTheme}
                        value={offerId}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Dropdown
                        options={[
                            {value: "", label: i18next.t("all")},
                            {value: "approved", label: i18next.t("approved")},
                            {value: "new", label: i18next.t("new")},
                            {value: "callbacks", label: i18next.t("callbacks")},
                            {value: "customer_reject", label: i18next.t("rejected")},
                            {value: "duplicated", label: i18next.t("duplicated")},
                            {value: "trash", label: i18next.t("trashed")},
                        ]}
                        onChange={this.handleDropDown}
                        value={status}
                        placeholder={i18next.t("status")}
                    />
                </div>

                <div className="col-6 col-lg-3">
                    <div className="d-flex align-items-center bg-daterangepicker">
                        <DateRangePicker onChange={this.onChange} value={this.state.date}/>
                    </div>
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Dropdown
                        options={[
                            {value: "today", label: i18next.t("today")},
                            {value: "yesterday", label: i18next.t("yesterday")},
                            {value: "last7days", label: i18next.t("last7days")},
                            {value: "last14days", label: i18next.t("last14days")},
                            {value: "lastMonth", label: i18next.t("lastMonth")},
                        ]}
                        onChange={this.handleDropDownQuickFilter}
                        value={quickFilter}
                        placeholder={i18next.t("quick_date_filter")}
                    />
                </div>
                <SummaryStatistics
                    approved={approved}
                    pending={pendingPayout}
                    approved_rate={approved_rate}
                    new_conv={new_conv}
                    callbacks={callbacks}
                    trash={trash}
                    customer_reject={customer_reject}
                    duplicated={duplicated}
                    pendingPayout={pendingPayout}
                    approvedPayout={approvedPayout}
                    rejectedPayout={rejectedPayout}
                    allConversions={allConversions}
                    changeStatus={(status) => this.setState({status}, () => this.filterConversion())}
                    deliveryStatusStats={deliveryStatusStats}

                />
                <ConversionStatisticsOffer
                    offerFilter={this.state.offerId}
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    synthesizedConversions={this.synthesizeConversionByOffer(this.state.conversions)}
                    handleClick={this.handleChangeStatisticsTable}
                />
                <ApprovedComboStatistics
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    synthesizedConversions={this.synthesizeApprovedComboConversions(this.state.conversions)}
                />
                <ConversionsStatisticsTwoHours
                    synthesizedConversions={this.synthesizeConversionsEveryTwoHours(this.state.conversions)}
                />
                <ConversionList
                    reloadConversions={this.reloadConversions}
                    conversions={this.state.conversions}
                    isLoading={this.state.isLoadingTable}
                />
            </Main>
        );
    }
}
