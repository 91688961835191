import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/table";
import i18next from "i18next";
// import "../../style/offers.css";
// import Pagination from "react-js-pagination";
// import "../../style/pagination.css";

export default class OfferLists extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const columns = [
            {
                name: "",
                selector: "imgLink",
                maxWidth: "400px",
                minWidth: "200px",
                sortable: true,
                center: true,
            },
            {
                name: i18next.t("offerAlias"),
                selector: "alias",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("offerName"),
                selector: "name",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("category"),
                selector: "category",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("salePrice"),
                selector: "price",
                sortable: true,
            },
            {
                name: i18next.t("commission"),
                selector: "payoutPub",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("isOfferCombo"),
                selector: "isCombo",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("resources"),
                selector: "resources",
                sortable: true,
                wrap: true,
            },
        ];
        return (
            <div className="col-12">
                <DataTable
                    columns={columns}
                    data={this.props.offers}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.props.isLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        );
    }
}

{
    /* <div className="col-12 Offers">
                <div className="py-3 bg-menu mb-1">
                    <div className="px-4">
                        <h2 className="menu-h2-title">Offer List</h2>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table mb-3 ">
                            <thead>
                                <tr>
                                    <th className="pl-4" scope="col"></th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Product Category</th>
                                    <th scope="col">Sale Price</th>
                                    <th scope="col">Commission</th>
                                    <th scope="col">ECPC</th>
                                    <th scope="col">Conversion Rate</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.offers.map((val, i) => (
                                    <tr key={i}>
                                        <td className="pl-4 img-offerlist">
                                            <img className="" src={val.img} alt={val.name} />
                                        </td>
                                        <td>{val.name}</td>
                                        <td>Category</td>
                                        <td>{val.price}</td>
                                        <td>totalearning</td>
                                        <td>{val.ecpc}</td>
                                        <td>{"rate"}</td>
                                        <td>
                                            <i
                                                className={`icon-devas icon-edit icon-small icon-pointer`}
                                                onClick={() => this.props.history.push(`/offers/${val.id}`)}
                                            ></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between px-4 pb-1 pb-md-0">
                        <div className="my-3 my-md-0">
                            <h5 className="text-pagination">
                                Displaying{" "}
                                <span className="d-inline mx-1">
                                    <select
                                        className="form-control w-auto d-inline-block h-auto"
                                        value={this.state.itemsPerPage}
                                        onChange={this.handleChange}
                                        name="itemsPerPage"
                                    >
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </span>{" "}
                                out of 1000 results
                            </h5>
                        </div>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={450}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </div> */
}
