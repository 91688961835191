import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import CodeMirror from "codemirror";
import "codemirror/addon/display/placeholder";
import "codemirror/lib/codemirror.css";
import "../../style/codemirror.css";
import "codemirror/theme/material-palenight.css";
import "codemirror/theme/dracula.css";

export default class CodeMirrorInputForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            log: "",
        };
    }

    componentDidMount() {
        this.editor = CodeMirror.fromTextArea(document.getElementById(this.props.name), {
            styleActiveLine: true,
            lineNumbers: true,
            lineWrapping: true,
        });
        this.editor.setOption("theme", localStorage.getItem("theme") === "light" ? "default" : "dracula");
        this.editor.on("change", (cMirror) => {
            var data = cMirror.getValue();
            this.props.handleDataValue(data);
            this.validateData(data);
        });
    }
    validateData = (string) => {
        let str = "";
        let strLine = [];
        //
        var lines = string.split(/\r*\n/);
        for (var i = 0; i < lines.length; i++) {
            if (i >= this.props.lineLimit) {
                this.editor.removeLineClass(i);
                this.editor.addLineClass(i, null, "bg-red");
            } else {
                if (!this.checkLead(lines[i])) {
                    this.editor.removeLineClass(i);
                    this.editor.addLineClass(i, null, "bg-red");
                    strLine.push(i);
                } else {
                    this.editor.removeLineClass(i);
                    this.editor.addLineClass(i, null, "bg-green");
                }
            }
        }
        if (strLine.length > 0) {
            str = `Line ${strLine.reduce((a, b, c) => {
                if (c !== strLine.length - 1) {
                    a += b + 1 + ", ";
                } else {
                    a += b + 1;
                }
                return a;
            }, "")} has/have wrong format`;
        }
        if (this.checkTotalLine(string) !== true) {
            str += `Form can only have ${this.props.lineLimit} lines!</br>`;
        }
        this.setState({ log: str !== "" ? str : "" });
        this.props.handleModalError(str !== "" || string === "");
    };
    checkTotalLine = (string) => {
        var linesTotal = string.split(/\r*\n/).length;
        return linesTotal <= 100;
    };
    checkLead = (string) => {
        return string.split(";").length - 1 === 1 ? string.match(/^([\W\w\d ]+);((\+)*)(\d){6}(\d)*$/g) : false;
    };
    render() {
        return (
            <>
                <textarea
                    className="form-control"
                    rows={this.props.rows}
                    required
                    placeholder={this.props.placeholder}
                    id={this.props.name}
                    name={this.props.name}
                    value={this.props.value}
                ></textarea>
                <div className="medium-text">{ReactHtmlParser(this.state.log)}</div>
            </>
        );
    }
}
