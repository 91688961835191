import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import {getLocalTimestamp, getStartOfDateTimestamp, moneyFormat} from "../../helper/helper";
import i18next from "i18next";

export default class OverallReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approved: "",
            rejected: "",
            pending: "",
            approved_rate: "",
            new_conv: "",
            callbacks: "",
            trash: "",
            customer_reject: "",
            duplicated: "",
            pendingPayout: 0,
            approvedPayout: 0,
            rejectedPayout: 0,
            total: "",
        };
    }

    componentDidMount() {
        this.signal = axios.CancelToken.source();
        var startDate = moment(Date.now()).startOf("date").unix() * 1000;
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/conversions/statistics?from_date=${startDate}&to_date=${Date.now()}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                let data = response.data;
                let total =
                    parseInt(data.approved) +
                    parseInt(data.new) +
                    parseInt(data.callbacks) +
                    parseInt(data.trash) +
                    parseInt(data.customer_reject) +
                    parseInt(data.duplicated);
                this.setState({
                    approved: data.approved,
                    approved_rate: data.approved_rate,
                    new_conv: data.new,
                    callbacks: data.callbacks,
                    trash: data.trash,
                    customer_reject: data.customer_reject,
                    duplicated: data.duplicated,
                    approvedPayout: data.approvedPayout,
                    pendingPayout: data.pendingPayout,
                    rejectedPayout: data.rejectedPayout,
                    approved_rate: data.approvalRate,
                    total,
                });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    render() {
        var {
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            pendingPayout,
            rejectedPayout,
            approvedPayout,
            total,
        } = this.state;
        return (
            <div className="pt-3 bg-menu mb-1">
                <div className="px-4">
                    <h2 className="menu-h2-title">{i18next.t("summary")}</h2>
                </div>
                <div className="row mx-2 mt-3">
                    <div className="col-6 order-0 col-lg-3 px-2">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <h5>{i18next.t("approval_rate")}</h5>
                            <h4 className="text-blue pb-2">{approved_rate}%</h4>
                            <h5>{i18next.t("all_conversions")}</h5>
                            <h4 className="text-blue">{total}</h4>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-2 order-lg-1 col-lg-3 px-2">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <h5>{i18next.t("approved_payout")}</h5>
                            <h4 className="pb-2 text-green">${moneyFormat(parseFloat(approvedPayout), 0, 3)}</h4>
                            <h5>{i18next.t("approved")}</h5>
                            <h4 className="text-green">{approved}</h4>
                        </div>
                    </div>
                    <div className="col-6 order-1 order-lg-2 col-lg-3 px-2">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <h5>{i18next.t("pending_payout")}</h5>
                            <h4 className="pb-2 text-orange">${moneyFormat(parseFloat(pendingPayout), 0, 3)}</h4>
                            <div className="d-flex">
                                <div>
                                    <h5>{i18next.t("new")}</h5>
                                    <h4 className="text-orange">{new_conv}</h4>
                                </div>
                                <div className="ml-4">
                                    <h5>{i18next.t("callbacks")}</h5>
                                    <h4 className="text-orange">{callbacks}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-3 col-lg-3 px-2">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex">
                                {/* <div>
                                    <h5>Rejected Payout</h5>
                                    <h4 className="pb-2 text-red">${moneyFormat(parseFloat(rejectedPayout), 2, 3)}</h4>
                                </div> */}
                                <div className="pb-2">
                                    <h5>{i18next.t("rejected")}</h5>
                                    <h4 className="text-red">{customer_reject}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="">
                                    <h5>{i18next.t("trashed")}</h5>
                                    <h4 className="text-red">{trash}</h4>
                                </div>
                                <div className="ml-4">
                                    <h5>{i18next.t("duplicated")}</h5>
                                    <h4 className="text-red">{duplicated}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-3 d-flex flex-row-reverse">
                    <button className="btn button-devas" onClick={() => this.props.history.push("/statistics")}>
                        {i18next.t("more_details")}<span className="ml-2">{">"}</span>
                    </button>
                </div>
            </div>
        );
    }
}
