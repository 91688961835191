import React, { Component } from "react";
import i18next from "i18next";
export default class AffLinks extends Component {
    render() {
        return (
            <div>
                <h2 className="guide-title">{i18next.t("affLinksTitle")}</h2>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <div>
                        {i18next.t("affLinksText1")}
                        <img className="w-100" src={process.env.PUBLIC_URL + "/imgs/guide/afflinks.png"} />
                    </div>
                </div>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <h2 className="guide-title">{i18next.t("note")}:</h2>
                    <div>
                        {i18next.t("affLinksText2")}
                        <img className="w-100" src={process.env.PUBLIC_URL + "/imgs/guide/parkingdomain1.png"} />
                    </div>
                </div>
            </div>
        );
    }
}
