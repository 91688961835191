import React, { Component } from "react";
import i18next from "i18next";
export default class WhatDevasCPOIs extends Component {
    render() {
        return (
            <>
                <h2 className="guide-title">{i18next.t("cpo_title")}</h2>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <h3 className="guide-section-title mb-0">{i18next.t("cpoDefinitionTitle")}</h3>
                    <div>
                        {i18next.t("cpoDefinitionText")}
                    </div>
                </div>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <h3 className="guide-section-title mb-0">{i18next.t("cpoWhyTitle")}</h3>
                    <div>
                        <ul>
                            <li>
                                {i18next.t("cpoWhyText1")}
                            </li>
                            <li>
                                {i18next.t("cpoWhyText2")}
                            </li>
                            <li>
                                {i18next.t("cpoWhyText3")}
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}
