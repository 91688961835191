import React, {Component} from "react";
import i18next from "i18next";

export default class ParkingDomain extends Component {
    render() {
        return (
            <>
                <h2 className="guide-title">{i18next.t("parkingDomainTitle")}</h2>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <ul>
                        <li>{i18next.t("parkingDomainText1")}</li>
                        <li>{i18next.t("parkingDomainText2")}</li>
                        <li>{i18next.t("parkingDomainText3")}</li>
                    </ul>
                    <img className="w-100" src={process.env.PUBLIC_URL + "/imgs/guide/parkingdomain2.png"}/>
                </div>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <h3 className="guide-section-title mb-0">Video:</h3>
                    <iframe height="300" className="w-100" src="https://www.youtube.com/embed/jgDn8RIE9_E" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen

                    ></iframe>
                </div>
            </>
        )
    }
}