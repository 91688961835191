import React, { Component } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { ExportExcel } from "../../helper/ExportDataToExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCog, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import InputForm from "./CodeMirrorInputForm";
import Modal from "react-bootstrap/Modal";
import "../../style/modal.css";
import i18next from "i18next";

registerPlugin(FilePondPluginFileEncode);

export default class OfferResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal1: false,
            showModal2: false,
            modalConversionsText: "",
            modalError: true,
            publisher_code: "",
            files: [],
            fileData: [],
        };
    }
    componentDidMount() {
        this.getUserDetail();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getUserDetail = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                this.setState({ publisher_code: response.data._id });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    submitConversions = () => {
        let conversions = this.state.modalConversionsText.split(/\r*\n/);
        conversions = conversions.map((v) => {
            let data = v.split(";");
            return {
                name: data[0],
                phone: data[1],
                offer: this.props.offer._id,
                publisher: this.state.publisher_code,
                aff_sub5: "Devas Multi Submit Tools",
            };
        });
        axios
            .post(
                `https://conversion.click/conversions/list`,
                {
                    conversions,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                alert("Submit Completed!");
                this.handleModal1Close();
            })
            .catch((error) => {
                alert("Something went Wrong! " + error.response.data.message);
            });
    };
    submitConversionsFilePond = () => {
        let conversions = [...this.state.fileData];
        conversions = conversions.map((v) => {
            let data = {
                ...v,
                aff_sub5: "Devas Excel Upload",
                offer: this.props.offer._id,
                publisher: this.state.publisher_code,
            };
            return data;
        });
        console.log(conversions);
        axios
            .post(
                `https://conversion.click/conversions/list`,
                {
                    conversions,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                alert("Submit Completed!");
                this.handleModal2Close();
            })
            .catch((error) => {
                alert("Something went Wrong! " + error.response.data.message);
            });
    };
    handleModal1Close = () => {
        this.setState({ showModal1: false, modalConversionsText: "", modalError: true });
    };
    handleModal2Close = () => {
        this.setState({ showModal2: false, files: [], fileData: [] });
    };
    handleModelTextChange = (modalConversionsText) => {
        this.setState({ modalConversionsText });
    };
    handleModalError = (modalError) => {
        this.setState({ modalError });
    };
    handleFilePond = (err, file) => {
        if (err) {
            console.log(err);
        }
        console.log(file);
        const wb = XLSX.read(file.getFileEncodeBase64String(), { type: "base64" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        let check = this.fileDataCheck(data);
        if (check === "") {
            this.setState({ fileData: data });
        } else {
            alert(check);
            this.setState({ files: [], fileData: [] });
        }
    };
    fileDataCheck = (data) => {
        var checkMessage = "";
        const keys = ["name", "phone", "address", "message", "quantity", "aff_sub1", "aff_sub2", "aff_sub3", "aff_sub4"];
        if (data.length === 0) {
            checkMessage = "File is Empty!";
        }
        data.forEach((v) => {
            let objKeys = Object.keys(v);
            objKeys.forEach((key) => {
                if (keys.indexOf(key) === -1) checkMessage = "Some conversions have wrong format!";
            });
            if (objKeys.indexOf("name") === -1 || objKeys.indexOf("phone") === -1) {
                checkMessage = "All Conversions must have name and phone";
            }
            if (v.name === "" || v.phone === "") {
                checkMessage = "Some conversions have empty name or phone!";
            }
        });
        return checkMessage;
    };
    render() {
        let { resourcesLink, _id, alias } = this.props.offer;
        let { modalError, fileData } = this.state;
        return (
            <div className="px-4">
                <h2 className="menu-h2-title medium-text">{i18next.t("conversionsInputTool2")}</h2>
                <button className="btn button-devas ml-5 mb-3 mt-1" onClick={() => this.setState({ showModal1: true })}>
                    Open Tools <FontAwesomeIcon icon={faCog} className="ml-1" size="xs" />
                </button>
                <h2 className="menu-h2-title medium-text">{i18next.t("excelInputTool2")}</h2>
                <button className="btn button-devas ml-5 mb-3 mt-1" onClick={() => this.setState({ showModal2: true })}>
                    Open Tools <FontAwesomeIcon icon={faCog} className="ml-1" size="xs" />
                </button>
                {resourcesLink && (
                    <>
                        <h2 className="menu-h2-title medium-text">
                            Resources Link{" "}
                            <a href={resourcesLink} target="_blank">
                                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" size="xs" />
                            </a>
                        </h2>
                    </>
                )}
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">{i18next.t("conversionsInputTool")}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="table-ul ml-1 mb-2 medium-text">
                            <li>{i18next.t("inputToolText1")}</li>
                            <li>{i18next.t("inputToolText2")}</li>
                            <li>{i18next.t("inputToolText3")}</li>
                            <li>{i18next.t("inputToolText4")}</li>
                            <li>{i18next.t("inputToolText5")}</li>
                        </ul>
                        <div className="medium-text mb-1">
                            Current Offer: <b>{alias}</b>
                        </div>
                        <InputForm
                            row="10"
                            name="codemirror"
                            placeholder="E.g: Le Bao Minh;0908888888"
                            lineLimit="100"
                            handleDataValue={this.handleModelTextChange}
                            handleModalError={this.handleModalError}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Cancel
                        </button>
                        {!modalError && (
                            <button type="button" className="btn button-devas" onClick={this.submitConversions}>
                                Submit
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">{i18next.t("excelInputTool")}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="table-ul ml-1 mb-2 medium-text">
                            <li>{i18next.t("excelToolText1")}</li>
                            <li>{i18next.t("excelToolText2")}</li>
                            <li>{i18next.t("excelToolText3")}</li>
                            <li>{i18next.t("excelToolText4")}</li>
                        </ul>
                        <div className="mb-2">
                            Download Example Files:{" "}
                            <FontAwesomeIcon
                                icon={faFileDownload}
                                size="lg"
                                className="hover-primary"
                                onClick={() =>
                                    ExportExcel(
                                        [
                                            {
                                                name: "",
                                                phone: "",
                                                address: "",
                                                message: "",
                                                aff_sub1: "",
                                                aff_sub2: "",
                                                aff_sub3: "",
                                                aff_sub4: "",
                                            },
                                        ],
                                        "example"
                                    )
                                }
                            />
                        </div>
                        <FilePond
                            ref={(ref) => (this.pond = ref)}
                            files={this.state.files}
                            onaddfile={this.handleFilePond}
                            beforeAddFile={(file) => {
                                if (file.fileType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                                    return true;
                                } else {
                                    alert("You can only upload Excel Files!!");
                                    return false;
                                }
                            }}
                            allowMultiple={false}
                            allowFileEncode={true}
                            oninit={() => console.log("FilePond instance has initialised", this.pond)}
                            onremovefile={(err, file) => {}}
                            onupdatefiles={(fileItems) => {
                                // Set currently active file objects to this.state
                                this.setState({
                                    files: fileItems,
                                });
                            }}
                        ></FilePond>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Cancel
                        </button>
                        {fileData.length > 0 && (
                            <button type="button" className="btn button-devas" onClick={this.submitConversionsFilePond}>
                                Submit
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
