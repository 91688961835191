import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import queryString from "query-string";

import "../../style/register.css";
import devaslogo from "../../img/devaslogo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons"
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import i18next from "i18next";

const PUB_URL = process.env.REACT_APP_PUB_URL;

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            agreementCheck: false,
            email: "",
            fullName: "",
            username: "",
            password: "",
            retypePassword: "",
            invalidMessage: queryString.parse(this.props.location.search).msg,
            validMessage: "",
            isMobile: window.innerWidth < 576,
            phone_number: "",
            zalo_number: "",
            facebook_profile_link: ""
        };
    }

    componentDidMount() {
        document.title = "Register";
        this.changeBackground();
        window.addEventListener(
            "resize",
            () => {
                this.changeBackground();
            },
            false
        );
        if (localStorage.getItem("token_pub")) {
            this.props.history.push("/dashboard");
        }
    }

    changeBackground = () => {
        this.setState(
            {
                isMobile: window.innerWidth < 576,
            },
            () => {
                if (this.state.isMobile) {
                    document.body.style = "background-color: #1b2039";
                } else {
                    document.body.style = "background-color: #f9f9f9";
                }
            }
        );
    };
    handleCheckbox = () => {
        this.setState((state, props) => {
            return {agreementCheck: !state.agreementCheck, invalidMessage: "", validMessage: ""};
        });
    };
    handleFormChange = (e) => {
        this.setState({[e.target.name]: e.target.value, invalidMessage: "", validMessage: ""});
    };
    handleFormSubmit = (e) => {
        e.preventDefault();
        this.setState({invalidMessage: "", validMessage: ""}, () => {
            const userRe = new RegExp("^[a-z0-9-_.]+$");
            const emailRe = /^\S+@\S+\.\S+$/;
            if (!userRe.test(this.state.username)) {
                this.setState({invalidMessage: "Username may only contain lowercase letters, numbers, hyphens (-), underscores (_) or dots (.)!"});
            } else if (this.state.password !== this.state.retypePassword) {
                this.setState({invalidMessage: "Retype Password is incorrect!"});
            } else if (this.state.password.length < 6) {
                this.setState({invalidMessage: "Password must have at least 6 characters!"});
            } else if (!emailRe.test(this.state.email)) {
                this.setState({invalidMessage: "Incorrect Email!"});
            } else if (!this.state.agreementCheck) {
                this.setState({invalidMessage: "You have to agree to our Terms and Conditions!"});
            } else {
                axios
                    .post(
                        `${PUB_URL}/users/register`,
                        {
                            username: this.state.username,
                            password: this.state.password,
                            email: this.state.email,
                            fullName: this.state.fullName,
                            phone_number: this.state.phone_number,
                            zalo_number: this.state.zalo_number,
                            facebook_profile_link: this.state.facebook_profile_link
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((response) => {
                        this.setState({validMessage: `Account ${response.data.username} created successfully!`}, this.autoLogin);
                    })
                    .catch((error) => {
                        this.setState({invalidMessage: error.response.data.message});
                    });
            }
        });
    };
    autoLogin = () => {
        axios
            .post(
                `${PUB_URL}/users/login`,
                {
                    username: this.state.username,
                    password: this.state.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                localStorage.setItem("token_pub", response.data.token);
                localStorage.setItem("createdDate", parseInt(Date.now() / 1000));
                this.props.loginTimeout();
                if (response.data.redirectUpdateProfile) {
                    this.props.history.push(`/update_profile/${this.state.username}`);
                } else this.props.history.push("/");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const theme = this.state.isMobile ? "dark-theme" : "light-theme";
        var {password, username, invalidMessage, email, retypePassword, validMessage, fullName, agreementCheck, phone_number, zalo_number, facebook_profile_link} = this.state;
        var isRegisterDisabled = !(username !== "" && password !== "" && email !== "" && retypePassword !== "" && fullName !== "" && agreementCheck && phone_number !== "" && zalo_number !== "" && facebook_profile_link !== "");
        var invalidClassName = invalidMessage === "" ? "" : "is-invalid";
        var validClassName = validMessage === "" ? "" : "is-valid";
        return (
            <div className={`Register d-flex justify-content-center align-items-center ${theme}`}>
                <div className="container login-page d-flex flex-column d-sm-block">
                    <div
                        className="flex-fill logo-title d-flex justify-content-center align-items-center text-center"
                        onClick={() => this.props.history.push("/")}
                    >
                        <img src={devaslogo} className="logo" alt="Devas"/>
                        <h1 className="text-header ml-1 ml-sm-3">Devas Network</h1>
                    </div>
                    <form
                        className="flex-fill login-form item-width d-flex flex-column justify-content-center d-sm-block"
                        onSubmit={this.handleFormSubmit}
                    >
                        <div className="form-group">
                            <label htmlFor="username">Email</label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">{i18next.t("fullName")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="fullName"
                                value={fullName}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">{i18next.t("username")}&nbsp;</label>
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip
                                    delay={{show: 250, hide: 400}}
                                >
                                    Username may only contain lowercase letters, numbers, hyphens (-), underscores (_)
                                    or dots (.).
                                </Tooltip>}
                            >
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    size="lg"
                                    className="hover-primary"
                                />
                            </OverlayTrigger>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("password")}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("retypePassword")}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="retypePassword"
                                value={retypePassword}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("phone_number")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="phone_number"
                                value={this.state.phone_number}
                                onChange={this.handleFormChange}
                                pattern='^[0-9]+$'
                                required
                            />
                            <small>{i18next.t("phone_number_note")}</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("zalo_number")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="zalo_number"
                                value={this.state.zalo_number}
                                onChange={this.handleFormChange}
                                pattern='^[0-9]+$'
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("facebook_profile_link")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="facebook_profile_link"
                                value={this.state.facebook_profile_link}
                                onChange={this.handleFormChange}
                                required
                            />
                        </div>
                        <div className="form-group form-check">
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    className="form-check-input checkbox"
                                    id="checkbox"
                                    onChange={this.handleCheckbox}
                                    checked={this.state.agreementCheck}
                                />
                                <span className="checkmark"></span>
                            </label>

                            <label className="form-check-label small-text pl-2" htmlFor="checkbox">
                                {i18next.t("agreeToDevasTermAndCondition")}
                            </label>
                        </div>
                        <input type="hidden" className={`form-control ${invalidClassName}`}/>
                        <input type="hidden" className={`form-control ${validClassName}`}/>
                        <div className="valid-feedback">{validMessage}</div>
                        <div className="invalid-feedback">{invalidMessage}</div>
                        <button
                            type="submit"
                            className={`btn btn-submit my-3 ${isRegisterDisabled ? "disabled" : ""}`}
                            disabled={isRegisterDisabled}
                        >
                            {i18next.t("registerAccount")}
                        </button>
                    </form>
                    <div
                        className="flex-fill d-flex flex-column flex-md-row justify-content-center justify-content-md-between item-width text-center mt-3 mt-md-4">
                        <Link to="/help" className="link-tag d-block mt-1 mt-md-0">
                            {i18next.t("devasTermsAndConditions")}
                        </Link>
                        <Link to={"/help"} className="link-tag link-primary d-block mt-3 mt-md-0">
                            {i18next.t("help")}
                        </Link>
                        <Link to="/" className="link-tag d-block d-sm-none mt-3">
                            {i18next.t("back")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
