import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "./style/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./helper/i18next";
import i18next from "i18next";

const language = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
i18next.changeLanguage(language);
ReactDOM.render(
    <BrowserRouter>
            <App/>
    </BrowserRouter>,
    document.getElementById("root")
);
serviceWorker.unregister();
