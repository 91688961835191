export const moneyFormat = (money, n, x) => {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return money.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};
export const getStartOfDateTimestamp = (date) => {
    return new Date(new Date(date).setUTCHours(0, 0, 0, 0));
};
export const getEndOfDateTimestamp = (date) => {
    return new Date(new Date(date).setUTCHours(23, 59, 59, 999));
};
export const getLocalTimestamp = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};
