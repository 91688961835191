import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class NotFound extends Component {
    componentDidMount() {
        document.title = "404 Not Found";
    }
    render() {
        var theme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light";
        return (
            <div className={`${theme}-theme bg-menu d-flex w-100vw h-100vh justify-content-center align-items-center font-raleway flex-column`}>
                <h1 className="display-1 text-color">404 Not Found</h1>
                <h2 className="mt-3 text-color">
                    Go back to <Link to="/">home</Link>
                </h2>
            </div>
        );
    }
}
