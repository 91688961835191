import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-dropdown";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Main from "./base/Main";
import OfferLists from "./base/OfferLists";
import redirectNoToken from "../helper/redirectNoToken";
import { moneyFormat } from "../helper/helper";

import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/offers.css";
import i18next from "i18next";
export default class Offers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerDropDown: "",
            offerDropDownLists: [{ value: "", label: "All" }],
            countryDropdown: "",
            countryDropDownLists: [
                { value: "", label: i18next.t("allCountries") },
                { value: "_th", label: i18next.t("thailand") },
                { value: "_ph", label: i18next.t("philippines") },
                { value: "_id", label: i18next.t("indonesia") },
                { value: "_vn", label: i18next.t("vietnam") }
            ],
            search: "",
            offers: [],
            offersLists: [],
            isLoading: true,
        };
    }
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        document.title = "Offers";
        redirectNoToken.bind(this)();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                var offers = response.data.offers;
                var category = offers.map((offer) => {
                    return offer.category.hasOwnProperty("name") ? offer.category.name : "No Category";
                });
                category = [...new Set(category)].map((value) => {
                    return { value: value, label: value };
                });
                category.push({ value: "", label: "All" });
                category = category.reverse();
                var res = offers.reverse().map((v, i) => ({
                    imgLink: (
                        <Link to={`/offers/${v.alias}`}>
                            <img className="img-offerlist" src={v.imgLink} />
                        </Link>
                    ),
                    alias: (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            {v.alias}
                        </Link>
                    ),
                    name: (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            {v.name}
                        </Link>
                    ),
                    category: v.category.name,
                    price: !v.isCombo ? (
                        moneyFormat(parseFloat(v.price), 0, 3) + " " + (v.currency ? v.currency : "")
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    isCombo: v.isCombo ? "Yes" : "No",
                    payoutPub: !v.isCombo ? (
                        `${moneyFormat(parseFloat(v.payoutPub), 0, 3)} ${v.systemCurrency}`
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    resources: v.resourcesLink ? (
                        <a href={v.resourcesLink} target="_blank">
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" size="xs" />
                        </a>
                    ) : (
                        ""
                    ),
                }));
                this.setState({
                    offersList: res,
                    offers: res,
                    isLoading: false,
                    offerDropDownLists: category,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false }, () => {
                    alert("No offer found! Please call (+84) 902-187-876 for additional help!");
                });
                console.log(error);
            });
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSearchForm = (e) => {
        e.preventDefault();
    };
    handleDropDown = (e) => {
        this.setState({ offerDropDown: e.value }, () => this.offerSearch());
    };
    handleCountryDropDown = (e) => {
        this.setState({ countryDropdown: e.value }, () => this.offerSearch());
    };
    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => this.offerSearch());
    };
    offerSearch = (e) => {
        console.log(this.state);
        this.setState((state, props) => {
            let offers = state.offersList.filter((offer) => {
                return (
                    offer.category.includes(state.offerDropDown) &&
                    (offer.name.props.children.toLowerCase().includes(state.search.toLowerCase()) ||
                        offer.alias.props.children.toLowerCase().includes(state.search.toLowerCase()))
                    && (offer.name.props.children.toLowerCase().includes(state.countryDropdown) ||
                    offer.alias.props.children.toLowerCase().includes(state.countryDropdown))
                );
            });
            return { offers };
        });
    };
    render() {
        var { offerDropDown, offerDropDownLists, search, offers, countryDropDownLists, countryDropdown } = this.state;
        return (
            <Main history={this.props.history} linkTo={["/offers"]} linkName={[i18next.t("offers")]} selectedSection={3}>
                <div className="col-12 col-lg-10">
                    <div className="row h-100">
                        <div className="col-12 col-sm-4 col-lg-6 pb-3 pt-lg-3">
                            <div className="bg-menu">
                                <form onSubmit={this.handleSearchForm}>
                                    <input
                                        type="text"
                                        className="form-control search-input"
                                        name="search"
                                        value={search}
                                        onChange={this.handleSearch}
                                        placeholder={i18next.t("searchOffers")}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 pb-3 pt-lg-3">
                            <Dropdown
                                options={offerDropDownLists}
                                onChange={this.handleDropDown}
                                value={offerDropDown}
                                placeholder={i18next.t("category")}
                            />
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 pb-3 pt-lg-3">
                            <Dropdown
                                options={countryDropDownLists}
                                onChange={this.handleCountryDropDown}
                                value={countryDropdown}
                                placeholder={i18next.t("country")}
                            />
                        </div>
                    </div>
                </div>
                <OfferLists offers={offers} history={this.props.history} isLoading={this.state.isLoading} />
            </Main>
        );
    }
}
