import React, {Component} from "react";
import axios from "axios";
import Select from "react-select";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import GetAppIcon from "@material-ui/icons/GetApp";
import {ExportExcel} from "../../helper/ExportDataToExcel";
import {selectTheme} from "../../style/reactselect";
import {customStyles} from "../../style/table";
import "../../style/dropdown.css";
import "../../style/modal.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "../../style/daterangepicker.css";
import InvoicesStatistics from "./InvoicesStatistics";
import moment from "moment";
import i18next from "i18next";

export default class pubInvoices extends Component {
    state = {
        dateType: {label: i18next.t("created_date"), value: "created_date"},
        date: [moment(new Date("2020-05-15T00:00:00")).startOf("date").unix() * 1000, new Date()],
        status: "",
        isLoading: false,
        pubInvoiceList: [],
        totalInvoices: 0,
        totalEstimatePayout: 0,
        totalRealPayout: 0,
        totalPaid: 0,

    }

    componentDidMount() {
        document.title = "Pub Invoices";
        this.getPubInvoiceList(this.state.status);
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getPubInvoiceList = (status) => {
        this.signal = axios.CancelToken.source();
        this.setState({isLoading: true, status: status});
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/invoices/getAllPubInvoices/?is_paid=${status}&from_date=${from_date}&to_date=${to_date}&modified_date=${this.state.dateType.value}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((res) => {
                let data = res.data.reverse().map((v) => {
                    v.download = (
                        <button className="btn button-devas-medium" onClick={() => this.exportData(v._id, v.name)}>
                            <GetAppIcon/>
                        </button>
                    );
                    v.is_paid = v.is_paid ? "true" : "false";
                    return v;
                });
                this.calculateSummary(res.data);
                this.setState({pubInvoiceList: data, isLoading: false});
            })
            .catch((error) => {
                console.log(error);
            })
    }
    exportData = (invoiceId, fileName) => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/invoices/getInvoiceDetail/${invoiceId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                ExportExcel(response.data.conversions, fileName);
            })
            .catch((error) => {
                alert("Something went wrong!");
            });
    };
    calculateSummary = (data) => {
        let totalInvoices = 0;
        let totalEstimatePayout = 0;
        let totalRealPayout = 0;
        let totalPaid = 0;
        data.forEach((e) => {
            totalInvoices++;
            totalEstimatePayout += e.payout ? e.payout : 0;
            totalRealPayout += e.real_payout ? e.real_payout : 0;
            if (e.is_paid && e.is_paid === "true") {
                totalPaid++;
            }
        });
        this.setState({totalEstimatePayout, totalInvoices, totalRealPayout, totalPaid});
    };
    handleChangeDateType = (dateType) => {
        this.setState({dateType}, () => this.getPubInvoiceList(this.state.status));
    };
    handleChangeDate = (date) => {
        if (date == null) {
            date = [new Date(), new Date()];
        }
        this.setState({date}, () => this.getPubInvoiceList(this.state.status));
    };

    render() {
        const columns = [
            {
                name: "Id",
                selector: "_id",
                wrap: true,
                sortable: true,
            },
            {
                name: i18next.t("order"),
                selector: "order_id",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("invoiceName"),
                selector: "name",
                wrap: true,
            },
            {
                name: i18next.t("isPaid"),
                selector: "is_paid",
                sortable: true,
            },
            {
                name: i18next.t("conversionCount"),
                selector: "conversion_count",
                sortable: true,
            },
            {
                name: i18next.t("pubId"),
                selector: "pub.username",
                wrap: true,
            },
            {
                name: i18next.t("estimatePayout"),
                selector: "payout",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("realPayout"),
                selector: "real_payout",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("invoiceLog"),
                selector: "invoice_log",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("download"),
                selector: "download",
                wrap: true,
            },
        ];
        return (
            <div className="col-12 Invoices">
                <div className="py-3 bg-menu mb-1">
                    <div className="px-4 d-flex justify-content-between align-items-center pb-3">
                        <div className="d-flex justify-content-start align-items-center">
                            <h2 className="menu-h2-title mb-0 mr-2">{i18next.t("pub_invoices")}</h2>
                            <button className="btn button-devas mr-2" onClick={() => this.getPubInvoiceList("")}>
                                {i18next.t("allInvoices")}
                            </button>
                            <button className="btn button-devas mr-2" onClick={() => this.getPubInvoiceList("false")}>
                                {i18next.t("pendingInvoices")}
                            </button>
                        </div>
                    </div>
                    <div className="row px-4">
                        <div className="col-12 col-md-6 pb-3">
                            <div className="dropdown-form">
                                <Select
                                    isMulti={false}
                                    options={[
                                        {label: i18next.t("created_date"), value: "false"},
                                        {label: i18next.t("modified_date"), value: "true"},
                                    ]}
                                    className="devas-select"
                                    classNamePrefix="devas-select"
                                    onChange={this.handleChangeDateType}
                                    theme={selectTheme}
                                    value={this.state.dateType}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 pb-3">
                            <div className="d-flex align-items-center bg-daterangepicker border-grey">
                                <DateRangePicker onChange={this.handleChangeDate} value={this.state.date}/>
                            </div>
                        </div>
                    </div>
                    <InvoicesStatistics
                        key={new Date()}
                        totalInvoices={this.state.totalInvoices}
                        totalEstimatePayout={this.state.totalEstimatePayout}
                        totalRealPayout={this.state.totalRealPayout}
                        totalPaid={this.state.totalPaid}
                        totalHome={this.state.totalHome}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                        <DataTable
                            columns={columns}
                            data={this.state.pubInvoiceList}
                            pagination
                            theme="myTheme"
                            className="table"
                            customStyles={customStyles}
                            noHeader={true}
                            progressPending={this.state.isLoading}
                            highlightOnHover={true}
                            defaultSortField="order"
                            defaultSortAsc={false}
                            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                        />
                    </div>
                </div>
            </div>
        );
    }
}