import React, {Component} from "react";
import "./style/app.css";
import {withRouter, Route, Switch, Redirect} from "react-router-dom";
import {createTheme} from "react-data-table-component";
import {myTheme} from "./style/table";
// import axios from "axios";

// const BASE_URL = "https://devas.xyz";

import Login from "./Component/user/Login";
import Logout from "./Component/user/Logout";
import Dashboard from "./Component/Dashboard";
import Statistics from "./Component/Statistics";
import Offers from "./Component/Offers";
import Postback from "./Component/Postback";
import Tools from "./Component/Tools";
import Invoices from "./Component/Invoices";
import Home from "./Component/Home";
import Error from "./Component/Error";
import OfferDetail from "./Component/OfferDetail";
import PostbackCreate from "./Component/PostbackCreate";
import Help from "./Component/Help";
import Register from "./Component/user/Register";
import NotFound from "./Component/NotFound";
import UserGuide from "./Component/user/UserGuide";
import UpdateProfile from "./Component/user/UpdateProfile";
import ChangePassword from "./Component/user/ChangePassword";
import WrongVersion from "./Component/WrongVersion";
import ApprovedRateStatistics from "./Component/base/ApprovedRateStatistics";
createTheme("myTheme", myTheme);

class App extends Component {
    componentDidMount() {
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "en");
        }
        if (localStorage.getItem("token_pub")) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.style = "background-color: #1b2039";
            } else {
                document.body.style = "background-color: #f9f9f9";
            }
            if (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate") > 172700) {
                localStorage.clear();
                this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            } else {
                this.loginTimeout();
            }

            // axios
            //     .get(`${BASE_URL}/api/v1.0/users/profile`, {
            //         headers: {
            //             "Content-Type": "application/json",
            //             Authorization: "JWT " + localStorage.getItem("token")
            //         }
            //     })
            //     .then(response => {
            //         this.loginTimeout();
            //     })
            //     .catch(error => {
            //         localStorage.clear();
            //         this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            //     });
        }
    }

    loginTimeout = () => {
        var time = 172800 - (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate"));
        this.loginCurrentTimeout = setTimeout(() => {
            localStorage.clear();
            this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
        }, time * 1000);
        if (localStorage.getItem("rememberMe") === "false") {
            window.addEventListener("beforeunload", () => {
                localStorage.clear();
            });
        }
    };

    componentWillUnmount() {
        clearTimeout(this.loginCurrentTimeout);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/login"
                    render={(routeProps) => (
                        <Login {...routeProps} key={Date.now()} loginTimeout={this.loginTimeout}/>
                    )}
                />
                <Route exact path="/register" render={(routeProps) => <Register {...routeProps} loginTimeout={this.loginTimeout} key={Date.now()}/>}/>
                <Route exact path="/logout" render={(routeProps) => <Logout {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/dashboard" render={(routeProps) => <Dashboard {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/statistics"
                       render={(routeProps) => <Statistics {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/offers/:alias"
                       render={(routeProps) => <OfferDetail {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/offers" render={(routeProps) => <Offers {...routeProps} key={Date.now()}/>}/>
                <Route
                    exact
                    path="/postback/create"
                    render={(routeProps) => <PostbackCreate {...routeProps} key={Date.now()}/>}
                />
                <Route exact path="/postback" render={(routeProps) => <Postback {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/tools" render={(routeProps) => <Tools {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/invoices" render={(routeProps) => <Invoices {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/help" render={(routeProps) => <Help {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/" render={(routeProps) => <Home {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/user_guide" render={(routeProps) => <UserGuide {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/update_profile/:username" render={(routeProps) => <UpdateProfile {...routeProps} key={Date.now()} isAdmin={false} />} />
                <Route exact path="/change_password" render={(routeProps) => <ChangePassword {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/wrong-version" render={(routeProps) => <WrongVersion {...routeProps} key={Date.now()}/>}/>
                <Route exact path={"/approved_rate_statistics"} render={(routeProps)=><ApprovedRateStatistics {...routeProps} key={Date.now()} />} />
                <Route exact path="/404" render={(routeProps) => <NotFound {...routeProps} key={Date.now()}/>}/>
                <Route exact path="/error" render={(routeProps) => <Error {...routeProps} key={Date.now()}/>}/>
                <Redirect to="/404"/>
            </Switch>
        );
    }
}

export default withRouter(App);
