import React, { Component } from "react";
import OfferAPILink from "./OfferAPILink";
import OfferHostedPage from "./OfferHostedPage";
import OfferResources from "./OfferResources";
import i18next from "i18next";

export default class OfferDetailLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
        };
    }
    render() {
        var { page } = this.state;
        return (
            <div className="col-12 mt-3">
                <div className="bg-menu pb-3">
                    <div className="container-fluid mb-3">
                        <div className="row">
                            <div
                                className={`col-4 text-center border-top-link cursor-pointer py-3 ${
                                    this.state.page === 0 ? "border-primary-link" : ""
                                }`}
                                onClick={() => this.setState({ page: 0 })}
                            >
                                <h2 className="menu-h2-title mb-0 medium-text">{i18next.t("hostedPage")}</h2>
                            </div>
                            <div
                                className={`col-4 text-center border-top-link cursor-pointer py-3 ${
                                    this.state.page === 1 ? "border-primary-link" : ""
                                }`}
                                onClick={() => this.setState({ page: 1 })}
                            >
                                <h2 className="menu-h2-title mb-0 medium-text">{i18next.t("APISubmitLink")}</h2>
                            </div>
                            <div
                                className={`col-4 text-center border-top-link cursor-pointer py-3 ${
                                    this.state.page === 2 ? "border-primary-link" : ""
                                }`}
                                onClick={() => this.setState({ page: 2 })}
                            >
                                <h2 className="menu-h2-title mb-0 medium-text">{i18next.t("toolsAndResources")}</h2>
                            </div>
                        </div>
                    </div>
                    {page === 0 ? (
                        <OfferHostedPage offer={this.props.offer} />
                    ) : page === 1 ? (
                        <OfferAPILink offer={this.props.offer} />
                    ) : (
                        <OfferResources offer={this.props.offer} />
                    )}
                </div>
            </div>
        );
    }
}
