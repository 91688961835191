import React, { Component } from "react"
import axios from "axios"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"

export default class OfferAPILink extends Component {
    constructor(props) {
        super(props)

        this.state = {
            url1: "https://conversion.click/conversions?",
            url2: "https://conversion.click/conversions/json",
            copied1: false,
            copied2: false,
            publisher_code: ""
        }
    }
    componentDidMount() {
        this.getUserDetail()
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled")
    }
    getUserDetail = () => {
        this.signal = axios.CancelToken.source()
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub")
                }
            })
            .then((response) => {
                this.setState({ publisher_code: response.data._id })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    render() {
        var { url1, url2, publisher_code } = this.state
        var url = url1 + "offer=" + this.props.offer._id + "&publisher=" + this.state.publisher_code + "&name=&phone=";
        return (
            <div className="px-4">
                <h2 className="menu-h2-title medium-text">Option 1: x-www-form-urlencoded</h2>
                <div className="row my-4">
                    <div className="col-12 col-lg-9">
                        <div className="d-flex align-items-center">
                            <input
                                type="text"
                                className="form-control search-input mr-2"
                                name="url1"
                                value={url}
                                placeholder="API Link"
                            />
                            <CopyToClipboard
                                text={url}
                                onCopy={() =>
                                    this.setState({ copied1: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ copied1: false })
                                        }, 1500)
                                    })
                                }
                            >
                                <button className="btn button-devas d-flex align-items-center">
                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            </CopyToClipboard>
                            <span
                                className={`medium-text text-green fade-out-transition ml-2 ${
                                    this.state.copied1 ? "opacity-1" : "opacity-0"
                                }`}
                            >
                                Copied.
                            </span>
                        </div>
                    </div>
                </div>
                <h2 className="menu-h2-title medium-text">Option 2: JSON</h2>
                <div className="row my-4">
                    <div className="col-12 col-lg-9">
                        <div className="d-flex align-items-center">
                            <input
                                type="text"
                                className="form-control search-input mr-2"
                                name="url1"
                                value={url2}
                                placeholder="API Link"
                            />
                            <CopyToClipboard
                                text={url2}
                                onCopy={() =>
                                    this.setState({ copied2: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ copied2: false })
                                        }, 1500)
                                    })
                                }
                            >
                                <button className="btn button-devas d-flex align-items-center">
                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            </CopyToClipboard>
                            <span
                                className={`medium-text text-green fade-out-transition ml-2 ${
                                    this.state.copied2 ? "opacity-1" : "opacity-0"
                                }`}
                            >
                                Copied.
                            </span>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table mb-3 border-bottom-thin-link">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Param</th>
                                        <th scope="col">Required</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>offer</td>
                                        <td>
                                            <input type="checkbox" checked="checked" readOnly />
                                        </td>
                                        <td>{this.props.offer._id}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>publisher</td>
                                        <td>
                                            <input type="checkbox" checked="checked" readOnly />
                                        </td>
                                        <td>{publisher_code}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>name</td>
                                        <td>
                                            <input type="checkbox" checked="checked" readOnly />
                                        </td>
                                        <td>Customer Name</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>phone</td>
                                        <td>
                                            <input type="checkbox" checked="checked" readOnly />
                                        </td>
                                        <td>Customer Phone</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>address</td>
                                        <td>
                                            <input type="checkbox" disabled />
                                        </td>
                                        <td>Customer Address</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>message</td>
                                        <td>
                                            <input type="checkbox" disabled />
                                        </td>
                                        <td>Additional Message</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>quantity</td>
                                        <td>
                                            <input type="checkbox" disabled />
                                        </td>
                                        <td>Default value is 1</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>gender</td>
                                        <td>
                                            <input type="checkbox" disabled />
                                        </td>
                                        <td>Customer Gender</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>aff_sub1 - aff_sub4</td>
                                        <td>
                                            <input type="checkbox" disabled />
                                        </td>
                                        <td>Affsub from 1 - 4 for tracking</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
