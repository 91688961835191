import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { selectTheme } from "../../style/reactselect";
import "../../style/dropdown.css";
import i18next from "i18next";
var thanks_list = [
    { name: "English", id: "default" },
    { name: "Vietnamese", id: "default_vn", region: "vietnam" },
    { name: "Thai", id: "default_th", region: "thailand" },
    { name: "Burmese (Myanmar)", id: "default_my", region: "myanmar" },
    { name: "Laos", id: "default_la", region: "laos" },
    { name: "Japanese", id: "default_jp", region: "japan" },
    { name: "Cambodia", id: "default_cam", region: "cambodia" },
];

export default class OfferHostedPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageDetails: [],
            parkingDomains: [],
            publisher_code: "",
            pubOfferConetents: {},
            copied1: false,
            parkingDomain: { value: "mediacontent.click", label: "mediacontent.click" },
            prelanding: "",
            landing: "",
            thanks: "default",
            scriptPrelanding: "",
            scriptLanding: "",
            scriptThanks: "",
            aff_sub1: "",
            aff_sub2: "",
            aff_sub3: "",
            aff_sub4: "",
        };
    }
    componentDidMount() {
        this.getPageDetail();
        this.getParkingDomains();
        this.getUserDetail();
        this.getPubOfferContents();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    componentDidUpdate(prevProps) {
        if (this.props.offer != prevProps.offer) {
            this.getPageDetail();
            this.getPubOfferContents();
        }
    }
    getPageDetail = () => {
        if (this.props.offer._id) {
            this.signal = axios.CancelToken.source();
            axios
                .get(`${process.env.REACT_APP_PUB_URL}/pagedetails/${this.props.offer._id}`, {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                })
                .then((response) => {
                    this.setState({ pageDetails: response.data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    getParkingDomains = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/domains`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                console.log(response.data);
                this.setState({
                    parkingDomains: [...response.data, { domainName: "mediacontent.click" }].reverse().map((e) => {
                        console.log(e);
                        return { value: e.domainName, label: e.domainName };
                    }),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getUserDetail = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                this.setState({ publisher_code: response.data._id });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getPubOfferContents = () => {
        if (this.props.offer._id) {
            this.signal = axios.CancelToken.source();
            axios
                .get(`${process.env.REACT_APP_PUB_URL}/offercontents/${this.props.offer._id}`, {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                })
                .then((response) => {
                    let data = response.data;
                    this.setState({
                        scriptLanding: data.landing_script ? data.landing_script : "",
                        scriptPrelanding: data.preland_script ? data.preland_script : "",
                        scriptThanks: data.thanks_script ? data.thanks_script : "",
                    });
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                });
        }
    };
    saveScript = () => {
        axios
            .post(
                `${process.env.REACT_APP_PUB_URL}/offercontents/${this.props.offer._id}`,
                {
                    preland_script: this.state.scriptPrelanding,
                    landing_script: this.state.scriptLanding,
                    thanks_script: this.state.scriptThanks,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((response) => {
                this.getPubOfferContents();
                alert("Script Saved!");
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    };
    handleChangeDomain = (parkingDomain) => {
        this.setState({ parkingDomain });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        let {
            pageDetails,
            parkingDomain,
            parkingDomains,
            prelanding,
            publisher_code,
            landing,
            thanks,
            aff_sub1,
            aff_sub2,
            aff_sub3,
            aff_sub4,
            scriptLanding,
            scriptPrelanding,
            scriptThanks,
        } = this.state;
        let prelandRedirect = `https://mediacontent.click/preview/${this.props.offer._id}/preland/`;
        let landingRedirect = `https://mediacontent.click/preview/${this.props.offer._id}/landing/`;
        let thanksRedirect = `https://mediacontent.click/preview/thanks/`;
        let url = "";
        if (this.state.landing !== "") {
            url = `https://${this.state.parkingDomain.value}/${this.state.publisher_code}/${this.props.offer._id}/`;
            if (this.state.prelanding !== "") {
                url += `preland/${this.state.prelanding}?landing=${this.state.landing}&thanks=${this.state.thanks}`;
            } else {
                url += `landing/${this.state.landing}?thanks=${this.state.thanks}`;
            }
            if (this.state.aff_sub1 !== "") {
                url += `&aff_sub1=${this.state.aff_sub1}`;
            }
            if (this.state.aff_sub2 !== "") {
                url += `&aff_sub2=${this.state.aff_sub2}`;
            }
            if (this.state.aff_sub3 !== "") {
                url += `&aff_sub3=${this.state.aff_sub3}`;
            }
            if (this.state.aff_sub4 !== "") {
                url += `&aff_sub4=${this.state.aff_sub4}`;
            }
        }
        console.log(url);
        return (
            <div className="px-4">
                {pageDetails.length === 0 ? (
                    <p>{i18next.t("noHostedPageAvailable")}</p>
                ) : (
                    <>
                        <div>
                            <h2 className="menu-h2-title medium-text">{i18next.t("hostedPageLink")}</h2>
                            <div className="row mt-2 mb-4">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="form-control search-input mr-2"
                                            name="url1"
                                            value={url}
                                            placeholder="Hosted Page Link"
                                        />
                                        <CopyToClipboard
                                            text={url}
                                            onCopy={() =>
                                                this.setState({ copied1: true }, () => {
                                                    setTimeout(() => {
                                                        this.setState({ copied1: false });
                                                    }, 1500);
                                                })
                                            }
                                        >
                                            <button className="btn button-devas d-flex align-items-center">
                                                Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                            </button>
                                        </CopyToClipboard>
                                        <span
                                            className={`medium-text text-green fade-out-transition ml-2 ${
                                                this.state.copied1 ? "opacity-1" : "opacity-0"
                                            }`}
                                        >
                                            Copied.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="menu-h2-title medium-text">{i18next.t("availableDomains")}</h2>
                            <div className="row mt-2 mb-4">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <Select
                                            isMulti={false}
                                            options={parkingDomains}
                                            className="devas-select w-100 box-shadow"
                                            classNamePrefix="devas-select"
                                            onChange={this.handleChangeDomain}
                                            theme={selectTheme}
                                            value={parkingDomain}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="menu-h2-title medium-text mb-2">{i18next.t("availableContents")}</h2>
                            <div className="row mt-2 mb-4">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <h3 className="menu-h3-title">Prelanding</h3>
                                            <div className="d-flex align-items-center ">
                                                <input
                                                    type="radio"
                                                    value=""
                                                    name="prelanding"
                                                    checked={prelanding === ""}
                                                    onChange={() => this.setState({ prelanding: "" })}
                                                />
                                                <label className="small-text-offer-details mb-0 ml-1">No Prelanding</label>
                                            </div>
                                            {pageDetails.map(
                                                (v, i) =>
                                                    v.type === "preland" && (
                                                        <div className="d-flex align-items-center mt-1" key={i}>
                                                            <input
                                                                type="radio"
                                                                value=""
                                                                name="prelanding"
                                                                checked={prelanding === v._id}
                                                                onChange={() => this.setState({ prelanding: v._id })}
                                                            />
                                                            <label className="small-text-offer-details mb-0 ml-1">{v.name}</label>
                                                            <a href={prelandRedirect + v._id} target="_blank">
                                                                <FontAwesomeIcon
                                                                    icon={faExternalLinkAlt}
                                                                    className="ml-2"
                                                                    size="xs"
                                                                />
                                                            </a>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                        <div className="col-4">
                                            <h3 className="menu-h3-title">Landing</h3>
                                            <div className="d-flex align-items-center ">
                                                <input
                                                    type="radio"
                                                    value=""
                                                    name="landing"
                                                    checked={landing === ""}
                                                    onChange={() => this.setState({ landing: "" })}
                                                />
                                                <label className="small-text-offer-details mb-0 ml-1">No Landing</label>
                                            </div>
                                            {pageDetails.map(
                                                (v, i) =>
                                                    v.type === "landing" && (
                                                        <div className="d-flex align-items-center mt-1" key={i}>
                                                            <input
                                                                type="radio"
                                                                value=""
                                                                name="landing"
                                                                checked={landing === v._id}
                                                                onChange={() => this.setState({ landing: v._id })}
                                                            />
                                                            <label className="small-text-offer-details mb-0 ml-1">{v.name}</label>
                                                            <a href={landingRedirect + v._id} target="_blank">
                                                                <FontAwesomeIcon
                                                                    icon={faExternalLinkAlt}
                                                                    className="ml-2"
                                                                    size="xs"
                                                                />
                                                            </a>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                        <div className="col-4">
                                            <h3 className="menu-h3-title">Thanks</h3>
                                            {thanks_list.map((v, i) => {
                                                if (
                                                    v.id === "default" ||
                                                    (v.id !== "default" && this.props.offer.geo.toLowerCase() === v.region)
                                                )
                                                    return (
                                                        <div className="d-flex align-items-center " key={i}>
                                                            <input
                                                                type="radio"
                                                                value="default"
                                                                name="thanks"
                                                                checked={thanks === v.id}
                                                                onChange={() => this.setState({ thanks: v.id })}
                                                            />
                                                            <label className="small-text-offer-details mb-0 ml-1">{v.name}</label>
                                                            <a href={thanksRedirect + v.id} target="_blank">
                                                                <FontAwesomeIcon
                                                                    icon={faExternalLinkAlt}
                                                                    className="ml-2"
                                                                    size="xs"
                                                                />
                                                            </a>
                                                        </div>
                                                    );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="menu-h2-title medium-text">Tracking sub</h2>
                            <div className="row mt-2 mb-4">
                                <div className="col-3">
                                    <label className="small-text-offer-details">Tracking Sub 1</label>
                                    <input
                                        type="text"
                                        className="form-control search-input w-75"
                                        name="aff_sub1"
                                        value={aff_sub1}
                                        placeholder="aff_sub1"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <label className="small-text-offer-details">Tracking Sub 2</label>
                                    <input
                                        type="text"
                                        className="form-control search-input w-75"
                                        name="aff_sub2"
                                        value={aff_sub2}
                                        placeholder="aff_sub2"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <label className="small-text-offer-details">Tracking Sub 3</label>
                                    <input
                                        type="text"
                                        className="form-control search-input w-75"
                                        name="aff_sub3"
                                        value={aff_sub3}
                                        placeholder="aff_sub3"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <label className="small-text-offer-details">Tracking Sub 4</label>
                                    <input
                                        type="text"
                                        className="form-control search-input w-75"
                                        name="aff_sub4"
                                        value={aff_sub4}
                                        placeholder="aff_sub4"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="menu-h2-title medium-text">Tracking Script</h2>
                            <div className="row mt-2 mb-4">
                                <div className="col-4">
                                    <label className="small-text-offer-details">Prelanding Script</label>
                                    <textarea
                                        rows="5"
                                        className="form-control search-input w-75"
                                        name="scriptPrelanding"
                                        value={scriptPrelanding}
                                        placeholder="Tracking Script..."
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="small-text-offer-details">Landing Script</label>
                                    <textarea
                                        rows="5"
                                        className="form-control search-input w-75"
                                        name="scriptLanding"
                                        value={scriptLanding}
                                        placeholder="Tracking Script..."
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="small-text-offer-details">Thanks Script</label>
                                    <textarea
                                        rows="5"
                                        className="form-control search-input w-75"
                                        name="scriptThanks"
                                        value={scriptThanks}
                                        placeholder="Tracking Script..."
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn button-devas-large" onClick={this.saveScript}>
                                    {i18next.t("save")}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
