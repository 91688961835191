import React from "react";
import Drawer from "@material-ui/core/Drawer";

export default function myDrawer(props) {
    return (
        <Drawer anchor={props.anchor} open={props.open} onClose={() => props.setOpen(false)} className={localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"}>
            {props.children}
        </Drawer>
    );
}
