import React, { Component } from "react";
import redirectNoToken from "../helper/redirectNoToken";
import Main from "./base/Main";
import ParkingDomain from "./base/ParkingDomain";
import i18next from "i18next";
export default class Tools extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        document.title = "Tools";
        redirectNoToken.bind(this)();
    }
    render() {
        return (
            <Main history={this.props.history} linkTo={["/tools"]} linkName={[i18next.t("tools")]} selectedSection={5}>
                <ParkingDomain />
            </Main>
        );
    }
}
