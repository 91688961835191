import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import "../../style/sidebar.css";

export default class SidebarDiv extends Component {
    render() {
        return (
            <Tooltip arrow title={this.props.sidebarSmall ? this.props.text : ""} placement="right">
                <div
                    className={`sidebar-div cursor-pointer d-flex align-items-center ${
                        this.props.isSelected ? "is-selected" : ""
                    }`}
                    onClick={this.props.onClick}
                >
                    <i
                        className={`sidebar-icon ${this.props.logo}`}
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Tooltip on right"
                    ></i>
                    <span className="pb-0 ml-4 sidebar-menu-text">{this.props.text}</span>
                </div>
            </Tooltip>
        );
    }
}
