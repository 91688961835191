import React, { Component } from "react";
import axios from "axios";
import Main from "./base/Main";
import Checkbox from "./base/CheckboxPostbackCreate";
import redirectNoToken from "../helper/redirectNoToken";
import i18next from "i18next";
const trackParams = [
    "aff_sub1",
    "aff_sub2",
    "aff_sub3",
    "aff_sub4",
    "aff_sub5",
    "offer_id",
    "offer_alias",
    "conversion_id",
    "status",
    "name",
    "phone",
    "message"
];
export default class PostbackCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invalidMsg: "",
            validMsg: "",
            url: "",
            trackParamsBoolean: [false, false, false, false, false, false, false, false, false, false, false, false]
        };
    }
    componentDidMount(){
        document.title = "Create Postback";
        redirectNoToken.bind(this)();
    }
    handleSubmit = e => {
        e.preventDefault();
        if (this.state.url === "") {
            this.setState({ invalidMsg: "Url is required" });
        } else {
            var url = this.state.url;
            trackParams.forEach((val, i) => {
                if (this.state.trackParamsBoolean[i]) {
                    if (url.lastIndexOf("?") === -1) {
                        url += `?${val}={${val}}&`;
                    } else {
                        url += `${val}={${val}}&`;
                    }
                }
            });
            url = url.slice(0, url.length - 1);
            axios
                .patch(
                    `${process.env.REACT_APP_PUB_URL}/postbacks`,
                    { postback_url: url },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_pub")
                        }
                    }
                )
                .then(response => {
                    this.setState({ url: "", validMsg: `Postback url created successfully! Url: ${response.data.postback_url}`  });
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.setState({ invalidMsg: error.response.data.error.message });
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, validMsg: "", invalidMsg: "" });
    };
    handleCheckbox = pos => {
        this.setState((state, props) => {
            var trackParamsBoolean = state.trackParamsBoolean;
            trackParamsBoolean[pos] = !trackParamsBoolean[pos];
            return {
                trackParamsBoolean
            };
        });
    };
    render() {
        var { url, invalidMsg, validMsg } = this.state;
        var inputClass = "";
        if (invalidMsg !== "") {
            inputClass = "is-invalid";
        }
        if (validMsg !== "") {
            inputClass = "is-valid";
        }
        return (
            <Main
                history={this.props.history}
                linkTo={["/postback", "/postback/create"]}
                linkName={[i18next.t("postback"), i18next.t("createPostback")]}
                selectedSection={4}
            >
                <div className="col-12 Postback">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">{i18next.t("createPostback")}</h2>
                        </div>

                        <form onSubmit={this.handleSubmit} className="normal-form">
                            <div className="px-4 row mt-2">
                                <div className="col-12 col-md-9 col-xl-6">
                                    <div className="form-group">
                                        <label htmlFor="url">Postback URL</label>
                                        <input
                                            type="text"
                                            className={`form-control ${inputClass}`}
                                            name="url"
                                            aria-describedby="url"
                                            value={url}
                                            onChange={this.handleChange}
                                            placeholder={"E.g: http://yourdomain.com/postback"}
                                        />
                                        <div className="invalid-feedback mt-2">{invalidMsg}</div>
                                        <div className="valid-feedback mt-2">{validMsg}</div>
                                    </div>
                                </div>
                                <div className="col-12 form-group">
                                    <label htmlFor="duplicateUrl">{i18next.t("trackingParams")}</label>
                                    <div className="d-flex flex-wrap">
                                        {trackParams.map((val, i) => (
                                            <Checkbox name={val} handleCheckbox={() => this.handleCheckbox(i)} key={val} />
                                        ))}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <button
                                            className="btn button-devas-gray-large mr-4"
                                            onClick={() => this.props.history.push("/postback")}
                                        >
                                            {i18next.t("cancel")}
                                        </button>
                                        <button className="btn button-devas-large">{i18next.t("save")}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Main>
        );
    }
}
