import React, { Component } from "react";
import Main from "./base/Main";
import OfferDetailLinks from "./base/OfferDetailLinks";
import axios from "axios";
import { Link } from "react-router-dom";
import { moneyFormat } from "../helper/helper";
import "../style/offerDetail.css";
import redirectNoToken from "../helper/redirectNoToken";
import i18next from "i18next";
export default class OfferDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offer: {},
        };
    }

    componentDidMount() {
        document.title = "Offer Detail";
        this.getOfferDetail();
        redirectNoToken.bind(this)();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getOfferDetail = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                var offers = response.data.offers;
                var offer = null;
                offers.forEach((v) => {
                    if (v.alias === this.props.match.params.alias) {
                        offer = v;
                    }
                });
                if (!offer) {
                    this.props.history.push("/404");
                } else {
                    this.setState({
                        offer,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    render() {
        var alias = this.props.match.params.alias;
        var { offer } = this.state;
        return (
            <Main
                history={this.props.history}
                linkTo={["/offers", "/offers/" + alias]}
                linkName={["Offers", alias]}
                selectedSection={3}
            >
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">{i18next.t("offerDetail")}</h2>
                        </div>
                        <div className="">
                            <div className="container background-dark2 my-2 rounded">
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-5 pr-0 pr-lg-4">
                                        <div
                                            className="img-responsive rounded d-flex justify-content-center align-items-center"
                                            style={{
                                                backgroundImage: `url('${offer.imgLink}')`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="col-12 col-lg-7 mt-4 mt-lg-0 pl-4">
                                        <h4 className="menu-h2-title mb-2">{offer.name}</h4>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("offer_id")}:</span> {offer._id}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("offerAlias")}:</span> {offer.alias}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("currency")}:</span> {offer.currency}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("isOfferCombo")}:</span>{" "}
                                            {offer.isCombo ? "Yes" : "No"}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("category")}:</span>{" "}
                                            {offer.category ? offer.category.name : ""}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">{i18next.t("geolocation")}:</span> {offer.geo}
                                        </h6>
                                        {offer.details && offer.details !== "" && (
                                            <h6 className="my-4 text">
                                                <span className="w-200 d-inline-block bold-text">{i18next.t("description")}:</span>{" "}
                                                {offer.details}
                                            </h6>
                                        )}
                                        {!offer.isCombo ? (
                                            <>
                                                <h6 className="my-4 text">
                                                    <span className="w-200 d-inline-block bold-text">{i18next.t("price")}:</span>{" "}
                                                    {moneyFormat(
                                                        parseFloat(!offer.price || offer.price === "" ? 0 : offer.price),
                                                        0,
                                                        3
                                                    ) +
                                                        " " +
                                                        (offer.currency ? offer.currency : "")}
                                                </h6>
                                                <h6 className="my-4 bold-text">
                                                    <span className="w-200 d-inline-block">{i18next.t("commission")} / {i18next.t("payout")}:</span>{" "}
                                                    <span className="color-primary">
                                                        {moneyFormat(
                                                            parseFloat(
                                                                !offer.payoutPub || offer.payoutPub === "" ? 0 : offer.payoutPub
                                                            ),
                                                            0,
                                                            3
                                                        ) + " " + offer.systemCurrency}
                                                    </span>
                                                </h6>
                                            </>
                                        ) : (
                                            <>
                                                {offer.comboChoices.map((v, i) => (
                                                    <>
                                                        <h5>Combo {i + 1}</h5>
                                                        <h6 className="my-4 text">
                                                            <span className="w-200 d-inline-block bold-text">{i18next.t("comboName")}:</span>{" "}
                                                            {v.name + " - " + v.alias}
                                                        </h6>
                                                        <h6 className="my-4 text">
                                                            <span className="w-200 d-inline-block bold-text">{i18next.t("priceCombo")}:</span>{" "}
                                                            {moneyFormat(
                                                                parseFloat(!v.price || v.price === "" ? 0 : v.price),
                                                                0,
                                                                3
                                                            ) +
                                                                " " +
                                                                (offer.currency ? offer.currency : "")}
                                                        </h6>
                                                        <h6 className="my-4 bold-text">
                                                            <span className="w-200 d-inline-block">{i18next.t("payoutCombo")} {i + 1}:</span>{" "}
                                                            <span className="color-primary">
                                                                {moneyFormat(
                                                                    parseFloat(
                                                                        !v.payoutPub || v.payoutPub === "" ? 0 : v.payoutPub
                                                                    ),
                                                                    0,
                                                                    3
                                                                ) + " " + offer.systemCurrency}
                                                            </span>
                                                        </h6>
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OfferDetailLinks history={this.props.history} offer={this.state.offer} />
            </Main>
        );
    }
}
