import React, { Component } from 'react'

export default class Error extends Component {
    componentDidMount(){
        localStorage.clear();
    }
    render() {
        return (
            <div>
                {this.props.error} There is an error occured. Your token might have been expired. Please Login again!
            </div>
        )
    }
}
