import React, { Component } from "react";
import "../../style/index.css";
import "../../style/user_guide_modal.css";
import { Link } from "react-router-dom";
import "../../style/modal.css";

export default class UserGuideModal extends Component {
    state = {}

    componentDidMount() {
        if (localStorage.getItem("hide_guide_modal")) {
            this.setState({
                hide_guide: true
            })
        } else {
            this.setState({
                hide_guide: false
            })
        }
    }

    handleTurnOffModal() {
        this.setState({
            hide_guide: true
        });
    }

    handleHideModal() {
        this.setState({
            hide_guide: true
        });
        localStorage.setItem("hide_guide_modal", "yes");
    }

    render() {
        return (
            <div className={`myModal modal user-guide-modal ${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} ` + (this.state.hide_guide ? "" : "active")} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Welcome!</h5>
                        </div>
                        <div className="modal-body">
                            <p>Familiarize yourself with Devas system by seeing our user guide</p>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn button-devas-gray"
                                onClick={() => this.handleHideModal()}>Don't show me this again
                            </button>
                            <Link to="/user_guide" onClick={() => this.handleTurnOffModal}>
                                <button type="button" className="btn button-devas" data-dismiss="modal" onClick={() => this.handleTurnOffModal()}>
                                    See the User Guide
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
