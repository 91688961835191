import React, { Component } from "react";
import i18next from "i18next";
export default class ApiLink extends Component {
    render() {
        return (
            <>
                <h2 className="guide-title">{i18next.t("apiLinkTitle")}</h2>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <div>
                        {i18next.t("apiLinkText1")}
                    </div>
                    <img className="w-100" src={process.env.PUBLIC_URL + "/imgs/guide/apilink.png"} />
                </div>
            </>
        )
    }
}
