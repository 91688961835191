import React, { Component } from "react";

import "../../style/GuideDrawer.css";

export default class GuideMenuItem extends Component {
    render() {
        return (
            <li className={`guide-list-item ${this.props.isActive ? "guide-list-item-active" : ""}`} onClick={this.props.onClick}>
                <span className={this.props.hasListStyle ? "list-item-circle" : ""}>{this.props.isCompleted ? "✔" : ""}</span>
                <label className="list-item-label">{this.props.children}</label>
            </li>
        );
    }
}
