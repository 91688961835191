import React,{Component} from "react";
import {customStyles} from "../../style/table";
import DataTable from "react-data-table-component";
import i18next from "i18next";
export default class ApprovedComboStatistics extends Component{
    render() {
        const columns = [
            {
                name: i18next.t("offer_id"),
                selector: "offer",
                maxWidth: "70px",
                sortable: true,
                center: true,
                wrap:true
            },
            {
                name: i18next.t("combo"),
                selector: "combo",
                sortable: true,
                center: true,
                wrap:true
            },
            {
                name: i18next.t("payoutPub"),
                selector: "payoutPub",
                sortable: true,
                center: true,
                wrap:true
            },
            {
                name: i18next.t("price"),
                selector: "price",
                sortable: true,
                center: true,
                wrap:true
            },
            {
                name: i18next.t("total"),
                selector: "total",
                sortable: true,
                center: true,
                wrap:true
            },

        ];
        return (
            <div className="col-12 col-md-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">{i18next.t("approved_combo_statistic")}</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={this.props.synthesizedConversions}
                    columns={columns}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    defaultSortField="name"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        )
    }
}