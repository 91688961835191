import React, {Component} from "react";
import axios from "axios";
import "../../style/homepage.css";
import devaslogo from "../../img/devaslogo.svg";
import {Link} from "react-router-dom";
import Select from "react-select";
import {selectTheme} from "../../style/reactselect";
import "../../style/guide.css";
import i18next from "i18next";

export default class UserInfo extends Component {
    state = {
        bank_name: "",
        bank_account_number: "",
        beneficiary_name: "",
        category_experience: [],
        country_experience: [],
        max_daily_conversions: "",
        warning_message: "",
        form_validation_messages: "",
        submitDisabled: true,
        isLoading: true,
        phone_number: "",
        zalo_number: "",
        facebook_profile_link: "",
        approach_customer_way: [],
        traffic_source: {},
        show_form: {
            phone_number: false,
            zalo_number: false,
            facebook_profile_link: false,
            approach_customer_way: false,
            traffic_source: false,
        },
        website_name: "",
        website_url: "",
        website_description: "",
        social_media_name: {},
        social_media_use_method: {},
        youtube_name: "",
        youtube_url: "",
        youtube_description: ""
    };

    componentDidMount() {
        if (!localStorage.getItem("token_admin") && !localStorage.getItem("token_pub")) {
            this.props.history.push("/");
        }
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users/`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                const user = response.data;
                this.setState({
                    bank_name: "bank_name" in user ? user.bank_name : "",
                    bank_account_number: "bank_account_number" in user ? user.bank_account_number : "",
                    beneficiary_name: "beneficiary_name" in user ? user.beneficiary_name : "",
                    max_daily_conversions: "max_daily_conversions" in user ? user.max_daily_conversions : "",
                    category_experience: user.category_experience.length > 0 ? user.category_experience : [],
                    country_experience: user.country_experience.length > 0 ? user.country_experience : [],
                    isLoading: false,
                    phone_number: user.phone_number ? user.phone_number : "",
                    zalo_number: user.zalo_number ? user.zalo_number : "",
                    facebook_profile_link: user.facebook_profile_link ? user.facebook_profile_link : "",
                    approach_customer_way: user.approach_customer_way.length > 0 ? user.approach_customer_way : [],
                    traffic_source: user.traffic_source ? user.traffic_source : {},
                    website_name: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("website_name") ? user.traffic_source_detail.website_name : "",
                    website_url: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("website_url") ? user.traffic_source_detail.website_url : "",
                    website_description: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("website_description") ? user.traffic_source_detail.website_description : "",
                    social_media_name: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("social_media_name") ? user.traffic_source_detail.social_media_name : "",
                    social_media_use_method: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("social_media_use_method") ? user.traffic_source_detail.social_media_use_method : "",
                    youtube_name: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("youtube_name") ? user.traffic_source_detail.youtube_name : "",
                    youtube_url: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("youtube_url") ? user.traffic_source_detail.youtube_url : "",
                    youtube_description: user.traffic_source_detail && user.traffic_source_detail.hasOwnProperty("youtube_description") ? user.traffic_source_detail.youtube_description : ""
                });
                if (
                    !("bank_name" in user) ||
                    !("bank_account_number" in user) ||
                    !("beneficiary_name" in user) ||
                    !("max_daily_conversions" in user) ||
                    user.category_experience.length === 0 ||
                    !user.category_experience ||
                    user.country_experience.length === 0 ||
                    !user.country_experience
                ) {
                } else {
                    this.setState({submitDisabled: false});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    checkAllFieldsFilled = () => {
        this.setState({
            submitDisabled:
                this.state.bank_name === "" ||
                this.state.bank_account_number === "" ||
                this.state.beneficiary_name === "" ||
                this.state.category_experience.length === 0 ||
                !this.state.category_experience ||
                this.state.country_experience.length === 0 ||
                !this.state.country_experience,
        });
    };
    handleInputChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.checkAllFieldsFilled();
            }
        );
    };
    handleSelectChange = (selectedOptions, event) => {
        this.setState({[event.name]: selectedOptions}, () => {
            this.checkAllFieldsFilled();
        });
    };
    handleFormSubmit = (e) => {
        e.preventDefault();
        const {
            bank_name,
            bank_account_number,
            beneficiary_name,
            category_experience,
            country_experience,
            max_daily_conversions,
            phone_number,
            zalo_number,
            facebook_profile_link,
            approach_customer_way,
            traffic_source,
            website_name,
            website_url,
            website_description,
            social_media_name,
            social_media_use_method,
            youtube_name,
            youtube_url,
            youtube_description
        } = this.state;
        if (
            bank_name === "" ||
            bank_account_number === "" ||
            beneficiary_name === "" ||
            max_daily_conversions === "" ||
            category_experience.length === 0 ||
            !category_experience ||
            country_experience.length === 0 ||
            !country_experience || !phone_number || !zalo_number || !facebook_profile_link || approach_customer_way.length === 0 || Object.keys(traffic_source).length === 0
        ) {
            alert("All Fields Are Required!");
            return false;
        }
        let traffic_source_detail = "";
        if (traffic_source.value === "website") {
            if (!website_name || !website_url || !website_description) {
                alert("All Fields Are Required!");
                return false;
            }
            traffic_source_detail = {
                website_name: website_name,
                website_url: website_url,
                website_description: website_description
            }
        }
        if (traffic_source.value === "social_media") {
            if (Object.keys(social_media_name).length === 0 || Object.keys(social_media_use_method).length === 0) {
                alert("All Fields Are Required!");
                return false;
            }
            traffic_source_detail = {
                social_media_name: social_media_name,
                social_media_use_method: social_media_use_method,
            }
        }
        if (traffic_source.value === "youtube_channel") {
            if (!youtube_name || !youtube_url || !youtube_description) {
                alert("All Fields Are Required!");
                return false;
            }
            traffic_source_detail = {
                youtube_name: youtube_name,
                youtube_url: youtube_url,
                youtube_description: youtube_description
            }
        }
        axios
            .post(
                `${process.env.REACT_APP_PUB_URL}/users/update_profile`,
                {
                    bank_name: bank_name,
                    bank_account_number: bank_account_number,
                    beneficiary_name: beneficiary_name,
                    category_experience: category_experience,
                    country_experience: country_experience,
                    max_daily_conversions: max_daily_conversions,
                    phone_number: phone_number,
                    zalo_number: zalo_number,
                    facebook_profile_link: facebook_profile_link,
                    approach_customer_way: approach_customer_way,
                    traffic_source: traffic_source,
                    traffic_source_detail: traffic_source_detail
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    alert("User Info Updated Successfully!");
                    this.props.toggle();
                    this.props.checkTask();
                    this.props.setDefault();
                } else {
                    this.setState({
                        form_validation_messages: response.data.message,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        const theme = this.state.isMobile ? "dark-theme" : "light-theme";
        const categoryExperienceOptions = [
            {label: "Adult", value: "adult"},
            {label: "Nutri (tpcn)", value: "nutri"},
            {label: "Medicine", value: "medicine"},
            {label: "Superstitions", value: "superstitions"},
            {label: "House Appliances", value: "house_appliances"},
            {label: "Others", value: "others"},
        ];
        const countryExperienceOptions = [
            {label: "Australia", value: "AU"},
            {label: "Brazil", value: "BR"},
            {label: "Cambodia", value: "KH"},
            {label: "Canada", value: "CA"},
            {label: "China", value: "CN"},
            {label: "Egypt", value: "EG"},
            {label: "Europe", value: "EU"},
            {label: "India", value: "IN"},
            {label: "Indonesia", value: "ID"},
            {label: "Japan", value: "JP"},
            {label: "Laos", value: "LA"},
            {label: "Malaysia", value: "MY"},
            {label: "Maldives", value: "MV"},
            {label: "Mexico", value: "MX"},
            {label: "Myanmar", value: "MM"},
            {label: "Nepal", value: "NP"},
            {label: "Nigeria", value: "NG"},
            {label: "New Zealand", value: "NZ"},
            {label: "Pakistan", value: "PK"},
            {label: "Philippines", value: "PH"},
            {label: "Qatar", value: "QA"},
            {label: "Russia", value: "RU"},
            {label: "Saudi Arabia", value: "SA"},
            {label: "Singapore", value: "SG"},
            {label: "South Africa", value: "ZA"},
            {label: "South Korea", value: "KR"},
            {label: "Sri Lanka", value: "LK"},
            {label: "Syria", value: "SY"},
            {label: "Taiwan", value: "TW"},
            {label: "Thailand", value: "TH"},
            {label: "Turkey", value: "TR"},
            {label: "Ukraine", value: "UA"},
            {label: "UNITED ARAB EMIRATES (UAE)", value: "AE"},
            {label: "United Kingdom (UK)", value: "GB"},
            {label: "United States of America (USA)", value: "USA"},
            {label: "Uzbekistan", value: "UZ"},
            {label: "Vietnam", value: "VN"},
        ];
        const approachCustomerOptions = [
            {label: i18next.t("pail_traffic_options"), value: "pail_traffic"},
            {label: i18next.t("email_chatbot_options"), value: "email_chatbot"},
            {label: i18next.t("have_traffic_from_web"), value: "have_traffic_from_web"},
            {label: i18next.t("kois_options"), value: "kol"},
            {label: i18next.t("cashback_options"), value: "cashback"},
            {label: i18next.t("others_options"), value: "others"},
            {label: i18next.t("unknown_options"), value: "unknown"},
        ];
        const trafficSourceOptions = [
            {label: "Website", value: "website"},
            {label: "Youtube Channel", value: "youtube_channel"},
            {label: "Social Media", value: "social_media"},
            {label: i18next.t("dont_have_traffic_source"), value: "dont_have_traffic_source"}
        ];
        let trafficSourceDetailForm = "";
        if (this.state.traffic_source.value === "website") {
            trafficSourceDetailForm = (
                <>
                    <div className="form-group">
                        <label htmlFor="website_name">{i18next.t("website_name")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="website_name"
                            id="website_name"
                            value={this.state.website_name}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website_url">{i18next.t("website_url")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="website_url"
                            id="website_url"
                            value={this.state.website_url}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website_description">{i18next.t("website_description")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="website_description"
                            id="website_description"
                            value={this.state.website_description}
                            onChange={this.handleInputChange}
                        />
                    </div>
                </>
            )
        }
        if (this.state.traffic_source.value === "youtube_channel") {
            trafficSourceDetailForm = (
                <>
                    <div className="form-group">
                        <label htmlFor="youtube_name">{i18next.t("youtube_name")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="youtube_name"
                            id="youtube_name"
                            value={this.state.youtube_name}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="youtube_url">{i18next.t("youtube_url")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="youtube_url"
                            id="youtube_url"
                            value={this.state.youtube_url}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="youtube_description">{i18next.t("youtube_description")}</label>
                        <input
                            required
                            type="text"
                            className="form-control"
                            name="youtube_description"
                            id="youtube_description"
                            value={this.state.youtube_description}
                            onChange={this.handleInputChange}
                        />
                    </div>
                </>
            )
        }
        if (this.state.traffic_source.value === "social_media") {
            trafficSourceDetailForm = (
                <>
                    <div className="form-group">
                        <label htmlFor="social_media_name">{i18next.t("social_media_name")}</label>
                        <Select
                            required
                            name="social_media_name"
                            isMulti={false}
                            options={[
                                {label: "Facebook", value: "facebook"},
                                {label: "Zalo", value: "zalo"},
                                {label: "Twitter", value: "twitter"},
                                {label: "Instagram", value: "instagram"},
                            ]}
                            value={this.state.social_media_name}
                            onChange={(selectedOptions, event) => {
                                this.handleSelectChange(selectedOptions, event)
                            }}
                            className="devas-select"
                            classNamePrefix="devas-select"
                            theme={selectTheme}
                            id="social_media_name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="social_media_use_method">{i18next.t("social_media_use_method")}</label>
                        <Select
                            required
                            name="social_media_use_method"
                            isMulti={false}
                            options={[
                                {label: i18next.t("pay_ads"), value: "pay_ads"},
                                {label: i18next.t("share_post"), value: "share_post"},
                            ]}
                            value={this.state.social_media_use_method}
                            onChange={(selectedOptions, event) => {
                                this.handleSelectChange(selectedOptions, event)
                            }}
                            className="devas-select"
                            classNamePrefix="devas-select"
                            theme={selectTheme}
                            id="social_media_use_method"
                        />
                    </div>
                </>
            )
        }
        var {isAdmin} = this.props;
        return (
            <>
                {this.state.isLoading ? (
                    <h2 className="guide-title">{i18next.t("updateUserInfo")}</h2>
                ) : (
                    <>
                        <h2 className="guide-title">{i18next.t("updateUserInfo")}</h2>
                        <div className="guide-subtitle mt-2-5">{i18next.t("updateNoti")}</div>
                        <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                            <form
                                className="d-flex flex-column justify-content-center d-sm-block"
                                onSubmit={this.handleFormSubmit}
                            >
                                <div className="form-group">
                                    <label htmlFor="phone_number">{i18next.t("phone_number")}</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="phone_number"
                                        name="phone_number"
                                        aria-describedby="phone_number"
                                        value={this.state.phone_number}
                                        onChange={this.handleInputChange}
                                        pattern='^[0-9]+$'
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zalo_number">{i18next.t("zalo_number")}</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="zalo_number"
                                        name="zalo_number"
                                        aria-describedby="zalo_number"
                                        value={this.state.zalo_number}
                                        onChange={this.handleInputChange}
                                        pattern='^[0-9]+$'
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bank_name">{i18next.t("bankName")}</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="bank_name"
                                        name="bank_name"
                                        aria-describedby="bank_name"
                                        value={this.state.bank_name}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bank_account_number">{i18next.t("bankAccountNumber")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="bank_account_number"
                                        id="bank_account_number"
                                        value={this.state.bank_account_number}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="beneficiary_name">{i18next.t("beneficiaryName")}</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        name="beneficiary_name"
                                        id="beneficiary_name"
                                        value={this.state.beneficiary_name}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="category_experience">{i18next.t("categoryExperience")}</label>
                                    <Select
                                        required
                                        name="category_experience"
                                        isMulti={true}
                                        options={categoryExperienceOptions}
                                        value={this.state.category_experience}
                                        onChange={(selectedOptions, event) => {
                                            this.handleSelectChange(selectedOptions, event);
                                        }}
                                        className="devas-select box-shadow"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="country_experience">{i18next.t("countryExperience")}</label>
                                    <Select
                                        required
                                        name="country_experience"
                                        isMulti={true}
                                        options={countryExperienceOptions}
                                        value={this.state.country_experience}
                                        onChange={(selectedOptions, event) => {
                                            this.handleSelectChange(selectedOptions, event);
                                        }}
                                        className="devas-select box-shadow"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="max_daily_conversions">{i18next.t("maxDailyConversions")}</label>
                                    <input
                                        required
                                        type="number"
                                        className="form-control"
                                        name="max_daily_conversions"
                                        id="max_daily_conversions"
                                        value={this.state.max_daily_conversions}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="approach_customer_way">{i18next.t("approach_customer_way")}</label>
                                    <Select
                                        required
                                        name="approach_customer_way"
                                        isMulti={true}
                                        options={approachCustomerOptions}
                                        value={this.state.approach_customer_way}
                                        onChange={(selectedOptions, event) => {
                                            this.handleSelectChange(selectedOptions, event);
                                        }}
                                        className="devas-select box-shadow"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                        id="approach_customer_way"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="traffic_source">{i18next.t("traffic_source")}</label>
                                    <Select
                                        required
                                        name="traffic_source"
                                        isMulti={false}
                                        options={trafficSourceOptions}
                                        value={this.state.traffic_source}
                                        onChange={(selectedOptions, event) => {
                                            this.handleSelectChange(selectedOptions, event);
                                        }}
                                        className="devas-select box-shadow"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                        id="traffic_source"
                                    />
                                </div>
                                {trafficSourceDetailForm}
                                <div className="invalid-feedback">{this.state.form_validation_messages}</div>
                                <button
                                    type="submit"
                                    className={`btn btn-submit mt-3 ${this.state.submitDisabled ? "disabled" : ""}`}
                                    disabled={this.state.submitDisabled}
                                >
                                    {i18next.t("update")}
                                </button>
                            </form>
                        </div>
                    </>
                )}
            </>
        );
    }
}
