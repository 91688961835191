import React, {Component} from "react";
import Main from "../base/Main";
import "../../style/user_guide.css"
export default class UserGuide extends Component {
    render() {
        return (
            <Main history={this.props.history} linkTo={["/dashboard"]} linkName={["User Guide"]} selectedSection={7}>
                <div className="col-12">
                    <iframe className="user_guild_pdf" src="https://drive.google.com/file/d/1M1ocN73UUCJiv29pVxlbfjBjqQQWe8WC/preview"></iframe>
                </div>
            </Main>
        );
    }
}