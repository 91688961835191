import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar } from "react-circular-progressbar";

import Drawer from "./Drawer";

import "react-circular-progressbar/dist/styles.css";
import "../../style/GuideDrawer.css";

import GuideMenuItem from "../guide/GuideMenuItem";
import CollapsibleGuideItem from "../guide/CollapsibleGuideItem";
import UserInfo from "../guide/UserInfo";
import GuideTest from "../guide/GuideTest";
import WhatDevasCPOIs from "../guide/WhatDevasCPOIs";
import AffLinks from "../guide/AffLinks";
import ParkingDomain from "../guide/ParkingDomain";
import ApiLink from "../guide/ApiLink";
import TrackingScript from "../guide/TrackingScript";
import i18next from "i18next";
const pageMap = (page, thiss) => {
    let pageM = {
        default: <GuideTest />,
        page5: <WhatDevasCPOIs />,
        page1: <UserInfo toggle={thiss.props.setOpen} checkTask={thiss.checkTask} setDefault={thiss.setDefault} />,
        page6: <AffLinks />,
        page7: <ParkingDomain />,
        page8: <ApiLink />,
        page9: <TrackingScript />,
    };
    return pageM[page] ? pageM[page] : "";
};

export default class GuideDrawer extends Component {
    constructor(props) {
        super();
        this.state = {
            page: "default",
            task: 0,
        };
    }

    componentDidMount() {
        this.checkTask();
    }

    setDefault = () => {
        this.setState({ page: "default" });
    };

    checkTask = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users/`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                const user = response.data;
                if (
                    !("bank_name" in user) ||
                    !("bank_account_number" in user) ||
                    !("beneficiary_name" in user) ||
                    !("max_daily_conversions" in user) ||
                    user.category_experience.length === 0 ||
                    !user.category_experience ||
                    user.country_experience.length === 0 ||
                    !user.country_experience
                ) {
                    this.setState({ task: 0 });
                } else {
                    this.setState({ task: 1 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    render() {
        let { page, task } = this.state;
        return (
            <Drawer anchor="right" open={this.props.open} setOpen={this.props.setOpen}>
                <div className="container-fluid drawer-width drawer-height drawer-guide overflow-hidden">
                    <div className="row h-100 overflow-hidden">
                        <div className="col-4 d-flex flex-column p-0 mm-0 bg-menu z-index-1 right-menu-guide">
                            <div className="guide-top-menu d-flex align-items-center justify-content-center">
                                <CircularProgressbar
                                    value={task / 1}
                                    maxValue={1}
                                    text={`${(task / 1) * 100}%`}
                                    strokeWidth={14}
                                />
                                <div className="guide-top-title">
                                    <h2 className="guide-title">{i18next.t("gettingStarted")}</h2>
                                    <p className="guide-subtitle">{task} {i18next.t("guideComplete")}</p>
                                </div>
                            </div>
                            <div className="p-15 border-bottom-menu guide-top">
                                <ul className="guide-menu-list">
                                    <GuideMenuItem
                                        isActive={page === "page1"}
                                        hasListStyle={true}
                                        onClick={() => this.setState({ page: "page1" })}
                                        isCompleted={this.state.task === 1 ? true : false}
                                    >
                                        {i18next.t("updateUserInfo")}
                                    </GuideMenuItem>
                                </ul>
                            </div>
                            <div className="p-15">
                                <CollapsibleGuideItem value={i18next.t("devasGuide")}>
                                    <ul className="guide-menu-list">
                                        <GuideMenuItem
                                            isActive={page === "page5"}
                                            hasListStyle={false}
                                            onClick={() => this.setState({ page: "page5" })}
                                        >
                                            Devas CPO
                                        </GuideMenuItem>
                                        <GuideMenuItem
                                            isActive={page === "page6"}
                                            hasListStyle={false}
                                            onClick={() => this.setState({ page: "page6" })}
                                        >
                                            Aff links
                                        </GuideMenuItem>
                                        <GuideMenuItem
                                            isActive={page === "page7"}
                                            hasListStyle={false}
                                            onClick={() => this.setState({ page: "page7" })}
                                        >
                                            Parking Domain
                                        </GuideMenuItem>
                                        <GuideMenuItem
                                            isActive={page === "page8"}
                                            hasListStyle={false}
                                            onClick={() => this.setState({ page: "page8" })}
                                        >
                                            API Link
                                        </GuideMenuItem>
                                        <GuideMenuItem
                                            isActive={page === "page9"}
                                            hasListStyle={false}
                                            onClick={() => this.setState({ page: "page9" })}
                                        >
                                            Tracking Script
                                        </GuideMenuItem>
                                    </ul>
                                </CollapsibleGuideItem>
                            </div>
                            <div className="mt-auto border-top-menu p-15">
                                <div className="small-text text-center">
                                    <FontAwesomeIcon icon={faPhone} className="mr-1" />
                                    <a href="tel:+84902187876" className="hover-primary">
                                        (+84) 902-187-876
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 p-4 bg-auth right-menu-guide">{pageMap(page, this)}</div>
                    </div>
                </div>
            </Drawer>
        );
    }
}
