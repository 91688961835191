import React, { Component } from "react";

import "../../style/guide.css";
import i18next from "i18next";
export default class GuideTest extends Component {
    render() {
        return (
            <>
                {/* <h2 className="guide-title"></h2>
                <div className="guide-subtitle mt-2-5"></div> */}

                <div className="bg-menu px-3 py-4 guide-body">
                    <h3 className="guide-section-title mb-3">{i18next.t("welcome")}</h3>
                    <div>{i18next.t("welcomeText")} </div>
                    {/* <h3 className="guide-section-title mt-4">Step 2: Verify whether the code was installed successfully</h3>
                    <div>Enter your website URL below, and we will verify whether the tracking code was installed or not.</div> */}
                </div>
            </>
        );
    }
}
