import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import versionCheck from "../../helper/versionCheck";
import "../../style/main.css";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import '../../style/reactflag.css'
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from 'react-html-parser';

export default class Main extends Component {
    state = {
        showAnnouncement: false,
        news_banner: "",
        hasBanner: false,
    }

    componentDidMount() {
        versionCheck.bind(this)();
        if (!localStorage.getItem("token_pub")) {
            this.props.history.push("/");
        }
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/users/`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((response) => {
                let user = response.data;
                if (user.category_experience.length === 0 || user.country_experience.length === 0 || !user.max_daily_conversions || !user.phone_number || !user.zalo_number || !user.facebook_profile_link || (!user.approach_customer_way || user.approach_customer_way.length === 0) || (!user.traffic_source || Object.keys(user.traffic_source).length === 0) || !user.bank_name || !user.bank_account_number || !user.beneficiary_name) {
                    this.props.history.push(`/update_profile/${user.username}`);
                }
                if (user.status === "inactive") {
                    if (user.isUserFirstTimeProfileUpdate) {
                        this.props.history.push(`/update_profile/${user.username}`);
                    } else {
                        localStorage.clear();
                        this.props.history.push("/");
                    }
                }
                if (user.news_banner) {
                    this.setState({news_banner: user.news_banner, hasBanner: true}, () => {
                        if (localStorage.getItem("show_announcement") === "true") {
                            this.setState({showAnnouncement: true});
                        }
                    })
                }
                // console.log(user);
                // if (!("bank_name" in user) || !("bank_account_number" in user) || !("beneficiary_name" in user) || !("max_daily_conversions") || user.category_experience.length === 0 || user.country_experience.length === 0) {
                //     this.props.history.push("/update_profile");
                // }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    switchLanguage = (lang) => {
        let language = "vi";
        if (lang === "US") {
            language = "en";
        }
        localStorage.setItem("lang", language);
        window.location.reload();
    }

    handleAnnouncement = () => {
        this.setState({showAnnouncement: true});
    }

    render() {
        return (
            <>
                <Sidebar history={this.props.history} selectedSection={this.props.selectedSection}
                         handleAnnouncement={this.handleAnnouncement} hasBanner={this.state.hasBanner}>
                    <div className="main">
                        <div className="main-header d-flex flex-row-reverse">
                            <ReactFlagsSelect
                                countries={["US", "VN"]}
                                onSelect={this.switchLanguage}
                                placeholder="Select A Language"
                                defaultCountry={localStorage.getItem("lang") === "en" ? "US" : "VN"}
                            />
                            {(this.state.hasBanner) ? <div className="p-3">
                                <button className="btn button-devas" onClick={() => {
                                    this.handleAnnouncement()
                                }}>Read Announcement
                                </button>
                            </div> : ""}

                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <nav className="col main-navigation d-flex align-items-center">
                                    <ul className="list-none m-0 pl-0 d-flex">
                                        <li key={-1}>
                                            <Link to="/" className="previous-link">
                                                Home
                                            </Link>
                                        </li>
                                        {this.props.linkTo.map((v, i) => (
                                            <li key={i}>
                                                <Link
                                                    to={v}
                                                    className={i === this.props.linkTo.length - 1 ? "current-link" : "previous-link"}
                                                >
                                                    {" > "}{this.props.linkName[i]}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                {this.props.children}
                                <div className="copyright">
                                    <div>
                                        Copyright © 2022 <span>Devas</span>. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sidebar>
                <Modal
                    size="lg"
                    show={this.state.showAnnouncement}
                    centered
                >
                    <Modal.Body>
                        {ReactHtmlParser(this.state.news_banner)}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center w-100">
                            <button type="button" className="btn button-devas" onClick={() => {
                                this.setState({showAnnouncement: false}, () => {
                                    localStorage.setItem("show_announcement", "false");
                                });

                            }}>
                                Understood
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
