export const StatisticsTable = {
    table: {
        style: {
            display: "table",
        },
    },
    tableWrapper: {
        style: {
            overflowX: "auto",
        },
    },
    rows: {
        style: {
            paddingLeft: "12px",
            paddingRight: "12px",
            borderBottomWidth: "1.5px!important",
            ":hover": {
                borderBottomColor: "var(--bg-block-inverse)!important",
                outlineColor: "var(--bg-block-inverse)!important",
                backgroundColor: "var(--primary)!important",
                cursor: "pointer"
            },
        },
    },
    headRow: {
        style: {
            fontWeight: "700",
            paddingLeft: "12px",
            paddingRight: "12px",
            backgroundColor: "var(--bg-block-inverse)",
            minHeight: "40px",
        },
    },
    headCells: {
        style: {
            fontWeight: "700",
            fontSize: "0.8rem",
        },
    },
    cells: {
        style: {
            fontSize: "0.8rem",
        },
    },
    pagination: {
        style: {
            paddingLeft: "12px",
            paddingRight: "12px",
            justifyContent: "flex-start",
            webkitJustifyContent: "flex-start",
            borderTopWidth: "2px",
            "@media (min-width: 992px)": {
                webkitJustifyContent: "flex-end",
                justifyContent: "flex-end",
            },
        },
    },
};
