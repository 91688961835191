import { SmsFailedSharp } from "@material-ui/icons";
import React, { Component } from "react";

import "../../style/GuideDrawer.css";

export default class CollapsibleGuideItem extends Component {
    constructor(props) {
        super();

        this.state = {
            open: props.open ? props.open : true,
        };
    }

    toggleOpen = () => {
        this.setState((state, props) => {
            return { open: !state.open };
        });
    };
    render() {
        return (
            <>
                <button className={`btn guide-collapsible py-0 ${this.state.open ? "collapsible-open" : ""}`} onClick={this.toggleOpen}>
                    <svg width="10" height="10" viewBox="0 0 5 7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M.941.594l4.13 2.942-4.13 2.94z" fill="#6A7088" fillRule="evenodd"></path>
                    </svg>
                    <span className="guide-collapsible-text">{this.props.value}</span>
                </button>
                {this.state.open && this.props.children}
            </>
        );
    }
}
