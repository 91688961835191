import React, { Component } from "react";

export default class CheckboxPostbackCreate extends Component {
    render() {
        return (
            <div className="form-group form-check w-checkbox">
                <label className="checkbox-container">
                    <input
                        type="checkbox"
                        className="form-check-input checkbox"
                        name={this.props.name}
                        onChange={this.props.handleCheckbox}
                    />
                    <span className="checkmark"></span>
                </label>

                <label className="form-check-label small-text pl-1" htmlFor="checkbox">
                    {this.props.name}
                </label>
            </div>
        );
    }
}
