import React, { Component } from "react";
import { moneyFormat } from "../../helper/helper";
import "../../style/statistics.css";
import i18next from "i18next";
export default class SummaryStatistics extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var {
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            pendingPayout,
            rejectedPayout,
            approvedPayout,
            allConversions,
            deliveryStatusStats,
        } = this.props;
        return (
            <div className="SummaryStatistics col-12 mb-4">
                <div className="row">
                    <div className="col-6 order-0 col-lg-3">
                        <div className="bg-menu px-3 py-3">
                            <h5>{i18next.t("approval_rate")}</h5>
                            <h4 className="pb-2 text-blue">{approved_rate}%</h4>
                            <h5>{i18next.t("all_conversions")}</h5>
                            <h4 className="text-blue cursor-pointer" onClick={() => this.props.changeStatus("")}>
                                {allConversions}
                            </h4>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-2 order-lg-1 col-lg-3">
                        <div className="bg-menu px-3 py-3">
                            <div className="d-flex">
                                <div>
                                    <h5>{i18next.t("approved_payout")}</h5>
                                    <h4 className="pb-2 text-green">${moneyFormat(parseFloat(approvedPayout), 0, 3)}</h4>
                                </div>
                                <div className="ml-2">
                                    <h5>{i18next.t("approved")}</h5>
                                    <h4 className="text-green cursor-pointer" onClick={() => this.props.changeStatus("approved")}>
                                        {approved}
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <h5>{i18next.t("deliveryStatusStats")}</h5>
                                    <h4 className="text-green">{deliveryStatusStats}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 order-1 order-lg-2 col-lg-3">
                        <div className="bg-menu px-3 py-3">
                            <h5>{i18next.t("pending_payout")}</h5>
                            <h4 className="pb-2 text-orange">{"$" + moneyFormat(parseFloat(pendingPayout), 0, 3)}</h4>
                            <div className="d-flex">
                                <div>
                                    <h5>{i18next.t("new")}</h5>
                                    <h4 className="text-orange cursor-pointer" onClick={() => this.props.changeStatus("new")}>
                                        {new_conv}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>{i18next.t("callbacks")}</h5>
                                    <h4
                                        className="text-orange cursor-pointer"
                                        onClick={() => this.props.changeStatus("callbacks")}
                                    >
                                        {callbacks}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-3 col-lg-3">
                        <div className="bg-menu px-3 py-3">
                            <div className="d-flex">
                                {/* <div>
                                    <h5>Rejected Payout</h5>
                                    <h4 className="pb-2 text-red">${moneyFormat(parseFloat(rejectedPayout), 2, 3)}</h4>
                                </div> */}
                                <div className="pb-2">
                                    <h5>{i18next.t("rejected")}</h5>
                                    <h4
                                        className="text-red cursor-pointer"
                                        onClick={() => this.props.changeStatus("customer_reject")}
                                    >
                                        {customer_reject}
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="">
                                    <h5>{i18next.t("trashed")}</h5>
                                    <h4 className="text-red cursor-pointer" onClick={() => this.props.changeStatus("trash")}>
                                        {trash}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>{i18next.t("duplicated")}</h5>
                                    <h4 className="text-red cursor-pointer" onClick={() => this.props.changeStatus("duplicated")}>
                                        {duplicated}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
