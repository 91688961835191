import React, { Component } from "react";
import i18next from "i18next";
export default class TrackingScript extends Component {
    render() {
        return (
            <>
                <h2 className="guide-title">{i18next.t("trackingScriptTitle")}</h2>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <div>
                        {i18next.t("trackingScriptText1")}
                    </div>
                    <img className="w-100" src={process.env.PUBLIC_URL + "/imgs/guide/trackingscript.png"} />
                </div>
                <div className="bg-menu mt-2-5 px-3 py-4 guide-body">
                    <h3 className="guide-section-title mb-0">{i18next.t("note")}:</h3>
                    <div>
                        <ul>
                            <li>{i18next.t("trackingScriptText2")}</li>
                            <li>{i18next.t("trackingScriptText3")}</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}
