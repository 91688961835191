import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import parseDate from "../../helper/parseDate";
import i18next from "i18next";
var CanvasJSChart = CanvasJS.CanvasJSChart;
CanvasJS.CanvasJS.addColorSet("devas", [
    //colorSet Array
    "#ff8f39",
    "#ee1280",
    "#e52d27",
    "#ab4199",
    "#2f80ed",
    "#27ae60",
]);

export default class DashboardChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            callbacks: [],
            new: [],
            pending: [],
            duplicated: [],
            trash: [],
            approved: [],
            customer_reject: [],
        };
    }

    componentDidMount() {
        this.signal = axios.CancelToken.source();
        var startDate =
            moment(Date.now() - 864000000)
                .startOf("date")
                .unix() * 1000;
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/conversions/statistics_by_date?from_date=${startDate}&to_date=${Date.now()}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                let data = response.data;
                data.forEach((val) => {
                    this.setState((state, props) => {
                        return {
                            [val.status]: [...state[val.status], { x: parseDate(val.created_date, "yyyy-mm-dd"), y: val.count }],
                        };
                    });
                });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message)
            });
    }

    toggleDataSeries = (e) => {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    };
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    render() {
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: "",
            },
            axisX: {
                valueFormatString: "DD MMM",
            },
            axisY: {
                title: "",
            },
            toolTip: {
                shared: true,
            },
            legend: {
                verticalAlign: "top",
                horizontalAlign: "center",
                reversed: true,
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    type: "stackedArea",
                    name: i18next.t("trashed"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.trash,
                },
                {
                    type: "stackedArea",
                    name: i18next.t("duplicated"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.duplicated,
                },
                {
                    type: "stackedArea",
                    name: i18next.t("rejected"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.customer_reject,
                },
                {
                    type: "stackedArea",
                    name: i18next.t("callbacks"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.callbacks,
                },
                {
                    type: "stackedArea",
                    name: i18next.t("new"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.new,
                },
                {
                    type: "stackedArea",
                    name: i18next.t("approved"),
                    showInLegend: true,
                    xValueFormatString: "D MMM",
                    dataPoints: this.state.approved,
                },
            ],
        };
        return (
            <div className="mt-4">
                <div className="py-3 bg-menu mb-1">
                    <div className="px-4">
                        <h2 className="menu-h2-title">{i18next.t("chart")}</h2>
                    </div>
                    <div className="px-3 px-lg-4 px-xl-5 pt-2 pb-0 pb-lg-1 chartContainer">
                        <CanvasJSChart
                            options={options}
                            onRef={(ref) => (this.chart = ref)}
                            containerProps={{ width: "100%", position: "relative", height: "100%" }}
                        />
                        <div className="block-trial"></div>
                    </div>
                </div>
            </div>
        );
    }
}
