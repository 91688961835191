import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import lang_en from "./translations/en.json";
import lang_vi from "./translations/vi.json";
const resources={
    en:{
        translation: lang_en
    },
    vi:{
        translation: lang_vi
    }
}
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;