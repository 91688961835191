import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../style/homepage.css";
import devaslogo from "../img/devaslogo.svg";
import i18next from "i18next";
export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 576,
        };
    }

    componentDidMount() {
        if (localStorage.getItem("token_pub")) {
            this.props.history.push("/dashboard");
        }
        document.title = "Devas - Home";
        this.changeBackground();
        window.addEventListener(
            "resize",
            () => {
                this.changeBackground();
            },
            false
        );
    }
    changeBackground = () => {
        this.setState(
            {
                isMobile: window.innerWidth < 576,
            },
            () => {
                if (this.state.isMobile) {
                    document.body.style = "background-color: #1b2039";
                } else {
                    document.body.style = "background-color: #f9f9f9";
                }
            }
        );
    };
    render() {
        const theme = this.state.isMobile ? "dark-theme" : "light-theme";
        return (
            <div className={`HomePage d-flex justify-content-center align-items-center ${theme}`}>
                <div className="container login-home-page d-flex flex-column">
                    <div className="logo-title flex-fill d-flex justify-content-center align-items-center text-center">
                        <img src={devaslogo} className="logo" alt="Devas" />
                        <h1 className="text-header ml-1 ml-sm-3">Devas Network</h1>
                    </div>
                    <div className="flex-fill d-flex flex-column align-items-center justify-content-center item-width text-center">
                        <Link to="/login" className={`btn btn-submit my-3`}>
                            {i18next.t("login")}
                        </Link>
                    </div>

                    <div className="flex-fill d-flex flex-column align-items-center justify-content-center item-width text-center">
                        <Link to="/register" className="link-tag link-primary mb-sm-1">
                            {i18next.t("registerButton")}
                        </Link>
                        <Link to="/help" className="link-tag mt-3 mt-sm-2">
                            {i18next.t("contactUs")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
