import React, { Component } from "react";

import redirectNoToken from "../helper/redirectNoToken";
import Main from "./base/Main";
import PubInvoices from "./base/PubInvoices";
import i18next from "i18next";
export default class Invoices extends Component {
    componentDidMount() {
        document.title = "Pub Invoices";
        redirectNoToken.bind(this)();
    }
    render() {
        return (
            <Main history={this.props.history} linkTo={["/invoices"]} linkName={[i18next.t("pub_invoices")]} selectedSection={6}>
                <PubInvoices />
            </Main>
        );
    }
}
