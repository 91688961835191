export default function parseDate(input, format) {
    //https://stackoverflow.com/questions/3085937/safari-js-cannot-parse-yyyy-mm-dd-date-format/3085993#3085993m
    format = format || "yyyy-mm-dd"; // default format
    var parts = input.match(/(\d+)/g),
        i = 0,
        fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) {
        fmt[part] = i++;
    });

    return new Date(parts[fmt["yyyy"]], parts[fmt["mm"]] - 1, parts[fmt["dd"]]);
}