import axios from "axios";
export default function versionCheck() {
    axios
        .get(`${process.env.REACT_APP_PUB_URL}/app-version`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            if (process.env.REACT_APP_VERSION !== response.data.version) {
                this.props.history.push("/wrong-version");
            }
        })
        .catch((error) => {
            //this.props.history.push("/error?message=" + error.message);
        });
}
