import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const BASE_URL = "https://devas.xyz";

export default class ActiveOffers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: []
        };
    }
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        // axios
        //     .get(`${BASE_URL}/api/v1.0/offers`, {
        //         cancelToken: this.signal.token,
        //         headers: {
        //             "Content-Type": "application/json",
        //             Authorization: "JWT " + localStorage.getItem("token")
        //         }
        //     })
        //     .then(response => {
        //         this.setState({ offers: response.data.items });
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    render() {
        return (
            <div className="mt-4">
                <div className="py-3 bg-menu">
                    <div className="px-4">
                        <h2 className="menu-h2-title">Active Offers</h2>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table mb-3 ">
                            <thead>
                                <tr>
                                    <th className="pl-4" scope="col">
                                        Offer ID
                                    </th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Clicks</th>
                                    <th scope="col">Total Sale</th>
                                    <th scope="col">Earning</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.offers.map((val, i) => (
                                    <tr key={i}>
                                        <td className="pl-4">{val.alias}</td>
                                        <td>{val.name}</td>
                                        <td>clickcount</td>
                                        <td>salecount</td>
                                        <td>totalearning</td>
                                        <td>{val.active ? "active" : "not active"}</td>
                                        <td>{val.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/offers" className="offer-link">
                            View All
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
