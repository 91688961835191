import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {customStyles} from "../../style/table";
import {ExportExcel} from "../../helper/ExportExcel";
import RefreshIcon from "@material-ui/icons/Refresh";
import i18next from "i18next";
// import Pagination from "react-js-pagination";
// import "../../style/pagination.css";

export default class ConversionList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const columns = [
            {
                name: i18next.t("conversion_id"),
                selector: "order_id",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("created_date"),
                selector: "created_date",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("modified_date"),
                selector: "modified_date",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("offerName"),
                selector: "offer",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("comboAlias"),
                selector: "comboAlias",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("category"),
                selector: "category",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("status"),
                selector: "status",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("deliver_status"),
                selector: "delivery_status",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("delivery_code"),
                selector: "delivery_code",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("quantity"),
                selector: "quantity",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("modifyCount"),
                selector: "modify_count",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("name"),
                selector: "name",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("phone"),
                selector: "phone",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("address"),
                selector: "address",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("saleMessage"),
                selector: "sale_message",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("message"),
                selector: "message",
                sortable: true,
                wrap: true
            },
            {
                name: i18next.t("affsub1"),
                selector: "affsub1",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("affsub2"),
                selector: "affsub1",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("affsub3"),
                selector: "affsub1",
                sortable: true,
                wrap: true,
            },
            {
                name: i18next.t("affsub4"),
                selector: "affsub1",
                sortable: true,
                wrap: true,
            },
        ];
        return (
            <>
                <div className="col-12 Statistics">
                    <div className="py-3 bg-menu">
                        <div className="px-4 d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <h2 className="menu-h2-title mb-0">{i18next.t("conversionList")}</h2>
                                <span className="cursor-pointer ml-1" onClick={this.props.reloadConversions}>
                                    <RefreshIcon/>
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <ExportExcel
                                    csvData={[...this.props.conversions]}
                                    fileName={"PubConversions_" + new Date().toLocaleDateString()}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={this.props.conversions}
                        pagination
                        theme="myTheme"
                        className="table"
                        customStyles={customStyles}
                        noHeader={true}
                        progressPending={this.props.isLoading}
                        highlightOnHover={true}
                        defaultSortField="order"
                        defaultSortAsc={false}
                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    />
                </div>
            </>
        );
    }
}

{
    /* <div className="table-responsive mt-3">
                        <table className="table mb-3 ">
                            <thead>
                                <tr>
                                    <th className="pl-4" scope="col">
                                        Offer ID
                                    </th>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">Clicks</th>
                                    <th scope="col">Total Sale</th>
                                    <th scope="col">Earning</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="pl-4">productid</td>
                                    <td>Product Name</td>
                                    <td>clickcount</td>
                                    <td>salecount</td>
                                    <td>totalearning</td>
                                    <td>status</td>
                                    <td>date/time</td>
                                </tr>
                                <tr>
                                    <td className="pl-4">productid</td>
                                    <td>Product Name</td>
                                    <td>clickcount</td>
                                    <td>salecount</td>
                                    <td>totalearning</td>
                                    <td>status</td>
                                    <td>date/time</td>
                                </tr>
                                <tr>
                                    <td className="pl-4">productid</td>
                                    <td>Product Name</td>
                                    <td>clickcount</td>
                                    <td>salecount</td>
                                    <td>totalearning</td>
                                    <td>status</td>
                                    <td>date/time</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between px-4 pb-1 pb-md-0">
                        <div className="my-3 my-md-0">
                            <h5 className="text-pagination">
                                Displaying{" "}
                                <span className="d-inline mx-1">
                                    <select
                                        className="form-control w-auto d-inline-block h-auto"
                                        value={this.state.itemsPerPage}
                                        onChange={this.handleChange}
                                        name="itemsPerPage"
                                    >
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </span>{" "}
                                out of 1000 results
                            </h5>
                        </div>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={450}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                        />
                    </div>
                 */
}
