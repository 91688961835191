import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import queryString from "query-string";

import "../../style/homepage.css";
import devaslogo from "../../img/devaslogo.svg";
import i18next from "i18next";
const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
const PUB_URL = process.env.REACT_APP_PUB_URL;

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rememberCheck: true,
            username: "",
            password: "",
            invalidMessage: queryString.parse(this.props.location.search).msg,
            isMobile: window.innerWidth < 576,
        };
    }

    componentDidMount() {
        document.title = "Login";

        this.changeBackground();
        window.addEventListener(
            "resize",
            () => {
                this.changeBackground();
            },
            false
        );
        // if (localStorage.getItem("token_pub") || localStorage.getItem("token_admin")) {
        //     if (!this.props.isAdmin) {
        //         this.props.history.push("/dashboard");
        //     } else {
        //         this.props.history.push("/admin/dashboard");
        //     }
        // }
        if (localStorage.getItem("token_pub")) {
            this.props.history.push("/dashboard");
        }
    }

    changeBackground = () => {
        this.setState(
            {
                isMobile: window.innerWidth < 576,
            },
            () => {
                if (this.state.isMobile) {
                    document.body.style = "background-color: #1b2039";
                } else {
                    document.body.style = "background-color: #f9f9f9";
                }
            }
        );
    };
    handleCheckbox = () => {
        this.setState((state, props) => {
            return {rememberCheck: !state.rememberCheck};
        });
    };
    handleFormChange = (e) => {
        this.setState({[e.target.name]: e.target.value, invalidMessage: ""});
    };
    handleFormSubmit = (e) => {
        e.preventDefault();
        if (this.state.username === "utoken") {
            localStorage.setItem("token_pub", this.state.password);
            localStorage.setItem("rememberMe", this.state.rememberCheck);
            localStorage.setItem("createdDate", parseInt(Date.now() / 1000));
            localStorage.setItem("theme", this.state.isMobile ? "dark" : "light");
            this.props.loginTimeout();
            if (localStorage.getItem("theme") === "dark") {
                document.body.style = "background-color: #1b2039";
            } else {
                document.body.style = "background-color: #f9f9f9";
            }
            this.props.history.push("/dashboard");
        } else {
            axios
                .post(
                    `${PUB_URL}/users/login`,
                    {
                        username: this.state.username,
                        password: this.state.password,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    localStorage.setItem("token_pub", response.data.token);
                    localStorage.setItem("rememberMe", this.state.rememberCheck);
                    localStorage.setItem("createdDate", parseInt(Date.now() / 1000));
                    localStorage.setItem("theme", this.state.isMobile ? "dark" : "light");
                    if (response.data.user_info.news_banner){
                        localStorage.setItem("show_announcement","true");
                    }
                    this.props.loginTimeout();
                    if (localStorage.getItem("theme") === "dark") {
                        document.body.style = "background-color: #1b2039";
                    } else {
                        document.body.style = "background-color: #f9f9f9";
                    }
                    this.props.history.push("/");
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({invalidMessage: error.response.data.message});
                });
        }
    };

    render() {
        const theme = this.state.isMobile ? "dark-theme" : "light-theme";
        var {password, username, invalidMessage} = this.state;
        var {isAdmin} = this.props;
        var isSignInDisabled = !(username !== "" && password !== "");
        var invalidClassName = invalidMessage === "" ? "" : "is-invalid";
        return (
            <div className={`HomePage d-flex justify-content-center align-items-center ${theme}`}>
                <div className="container login-page d-flex flex-column d-sm-block">
                    <div
                        className="flex-fill logo-title d-flex justify-content-center align-items-center text-center"
                        onClick={() => this.props.history.push("/")}
                    >
                        <img src={devaslogo} className="logo" alt="Devas"/>
                        <h1 className="text-header ml-1 ml-sm-3">Devas {isAdmin ? "Manager" : "Network"}</h1>
                    </div>
                    <form
                        className="flex-fill login-form item-width d-flex flex-column justify-content-center d-sm-block"
                        onSubmit={this.handleFormSubmit}
                    >
                        <div className="form-group">
                            <label htmlFor="username">{i18next.t("username")}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                aria-describedby="username"
                                value={username}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">{i18next.t("password")}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                value={password}
                                onChange={this.handleFormChange}
                            />
                        </div>
                        <div className="form-group form-check">
                            <label className="checkbox-container">
                                <input
                                    type="checkbox"
                                    className="form-check-input checkbox"
                                    id="checkbox"
                                    onChange={this.handleCheckbox}
                                    checked={this.state.rememberCheck}
                                />
                                <span className="checkmark"></span>
                            </label>

                            <label className="form-check-label small-text pl-2" htmlFor="checkbox">
                                {i18next.t("rememberMe")}
                            </label>
                        </div>
                        <input type="hidden" className={`form-control ${invalidClassName}`}/>
                        <div className="invalid-feedback">{invalidMessage}</div>
                        <button
                            type="submit"
                            className={`btn btn-submit my-3 ${isSignInDisabled ? "disabled" : ""}`}
                            disabled={isSignInDisabled}
                        >
                            {i18next.t("signin")} {isAdmin ? "as Admin" : ""}
                        </button>
                    </form>
                    <div
                        className="flex-fill d-flex flex-column flex-md-row justify-content-center justify-content-md-between item-width text-center mt-3 mt-md-4">
                        <Link to="/help" className="link-tag d-block mt-1 mt-md-0">
                            {i18next.t("forgotPassword")}
                        </Link>
                        <Link
                            to="/register"
                            className="link-tag link-primary d-block mt-3 mt-md-0"
                        >
                            {i18next.t("registerButton")}
                        </Link>
                        <Link to="/" className="link-tag d-block d-sm-none mt-3">
                            {i18next.t("back")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
