import React, { Component } from "react";

import Main from "./base/Main";
import OverallReport from "./base/OverallReport";
import DashboardChart from "./base/DashboardChart";
import UserGuideModal from "./user/UserGuideModal";

import ActiveOffers from "./base/ActiveOffers";
import redirectNoToken from "../helper/redirectNoToken";
import "../style/dashboard.css";
import i18next from "i18next";
export default class Dashboard extends Component {
    componentDidMount() {
        document.title = "Dashboard";
        redirectNoToken.bind(this)();
    }
    render() {
        return (
            <Main history={this.props.history} linkTo={["/dashboard"]} linkName={[i18next.t("dashboard")]} selectedSection={1}>
                <div className="col-12">
                    <div className="Dashboard">
                        <OverallReport history={this.props.history} />
                        <DashboardChart/>
                    </div>
                </div>
            </Main>
        );
    }
}
