import React, {Component} from "react";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import "../../style/dashboard.css";
import i18next from "i18next";

const CanvasJSChart = CanvasJS.CanvasJSChart;


export default class ConversionsStatisticsTwoHours extends Component{
    render() {
        CanvasJS.CanvasJS.addColorSet("devas", [
            //colorSet Array
            "#6F4242",
            "#CD5C5C",
            "#EEB4B4",
            "#FF3300",
            "#EE4000",
            "#FF6103",
            "#FFB00F",
            "#54632C",
            "#615E3F",
            "#8B7D6B",
            "#8B4500",
            "#F87531",
            "#CD3700",
            "#FF5721",
            "#FF3300",

        ]);
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: i18next.t("two_hours_chart_title"),
                fontFamily: "Open Sans, sans-serif",
            },
            axisY:{
                title: i18next.t("conversions_count"),
                gridThickness: 0,
                fontFamily: "Open Sans, sans-serif",
            },
            toolTip: {
                shared: true,
            },
            data: [
                {
                    type: "column",
                    showInLegend: false,
                    toolTipContent: "<b>{label}h</b>:{y}",
                    indexLabelFormatter: (e) => {
                        return e.dataPoint.y
                    },
                    dataPoints: this.props.synthesizedConversions
                }
            ]
        }
        return (
            <div className="col-12 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0"></h2>
                        </div>
                    </div>
                    <div className="px-4 pt-3">
                        <CanvasJSChart
                            options={options}
                            /* onRef = {ref => this.chart = ref} */
                        />
                        <div className="block-trial"></div>
                    </div>
                </div>

            </div>
        )
    }
}