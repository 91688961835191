import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import "../style/homepage.css";
import devaslogo from "../img/devaslogo.svg";
export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 576,
        };
    }

    componentDidMount() {
        document.title = "Help";
        this.changeBackground();
        window.addEventListener(
            "resize",
            () => {
                this.changeBackground();
            },
            false
        );
    }
    changeBackground = () => {
        this.setState(
            {
                isMobile: window.innerWidth < 576,
            },
            () => {
                if (this.state.isMobile) {
                    document.body.style = "background-color: #1b2039";
                } else {
                    document.body.style = "background-color: #f9f9f9";
                }
            }
        );
    };
    render() {
        const theme = this.state.isMobile ? "dark-theme" : "light-theme";
        return (
            <div className={`HomePage d-flex justify-content-center align-items-center ${theme}`}>
                <div className="container login-home-page d-flex flex-column">
                    <div className="logo-title flex-fill d-flex justify-content-center align-items-center text-center mb-1">
                        <img src={devaslogo} className="logo" alt="Devas" />
                        <h1 className="text-header ml-1 ml-sm-3">Devas Support</h1>
                    </div>
                    <div className="flex-fill d-flex flex-column align-items-center justify-content-center item-width text-center">
                        <a href="https://t.me/NTCris" className={`btn btn-submit my-3`}>
                            <span className="mr-1">General Support</span> <FontAwesomeIcon icon={faTelegram} size="lg"/>
                        </a>
                        <a href="https://t.me/dinhlongviolin1" className={`btn btn-submit my-2`}>
                            <span className="mr-1">Technical Support</span> <FontAwesomeIcon icon={faTelegram} size="lg"/>
                        </a>
                        <a href="tel:+84902187876" className={`btn btn-submit my-3`}>
                            <span className="mr-1">Call Us Now (+84)</span> <FontAwesomeIcon icon={faPhone}/>
                        </a>
                    </div>

                    <div className="flex-fill d-flex flex-column align-items-center justify-content-center item-width text-center">
                        <Link to="/" className="link-tag mt-3 mt-sm-2">
                            Go Home
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
