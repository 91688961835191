import React, { Component } from "react";
import Main from "../base/Main";
import i18next from "i18next";
import axios from "axios";

export default class ChangePassword extends Component {
    state = {
        oldPassword: "",
        newPassword: "",
        retypeNewPassword: "",
        failMessage: "",
        successMessage: "",
    };
    passwordOnChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };
    submitForm = () => {
        if (this.state.oldPassword === "" || this.state.newPassword === "" || this.state.retypeNewPassword === "") {
            this.setState({
                successMessage: "",
                failMessage: i18next.t("allFieldRequired"),
            });
            return false;
        }
        if (this.state.oldPassword.length < 6 || this.state.newPassword.length < 6 || this.state.retypeNewPassword.length < 6) {
            this.setState({
                successMessage: "",
                failMessage: i18next.t("passwordLengthErr"),
            });
            return false;
        }
        if (this.state.oldPassword === this.state.newPassword) {
            this.setState({
                successMessage: "",
                failMessage: i18next.t("newOldPassEqual"),
            });
            return false;
        }
        if (this.state.newPassword !== this.state.retypeNewPassword) {
            this.setState({
                successMessage: "",
                failMessage: i18next.t("retypeNotEqual"),
            });
            return false;
        }
        axios
            .post(
                `${process.env.REACT_APP_PUB_URL}/users/change_password`,
                {
                    password: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((res) => {
                this.setState({
                    failMessage: "",
                    successMessage: res.data.message,
                });
            })
            .catch((err) => {
                this.setState({
                    successMessage: "",
                    failMessage: err.response.data.message,
                });
            });
    };

    render() {
        return (
            <Main
                history={this.props.history}
                linkTo={["/change_password"]}
                linkName={[i18next.t("changePassword")]}
                selectedSection={100}
            >
                <div className="col-12">
                    <div className="ChangePassword">
                        <div className="pt-3 bg-menu mb-1">
                            <div className="px-4 text-center">
                                <h2 className="bold-text">{i18next.t("changePasswordForm")}</h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-9 col-md-6 col-lg-4 py-4">
                                    <form className="normal-form" method="post">
                                        <div className="form-group">
                                            <label>{i18next.t("oldPassword")}</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="oldPassword"
                                                value={this.state.oldPassword}
                                                onChange={(event) => this.passwordOnChange(event.target.name, event.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{i18next.t("newPassword")}</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="newPassword"
                                                value={this.state.newPassword}
                                                onChange={(event) => this.passwordOnChange(event.target.name, event.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{i18next.t("retypeNewPassword")}</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="retypeNewPassword"
                                                value={this.state.retypeNewPassword}
                                                onChange={(event) => this.passwordOnChange(event.target.name, event.target.value)}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button
                                                type="button"
                                                onClick={() => this.submitForm()}
                                                className="btn button-devas-large"
                                            >
                                                {i18next.t("changePasswordButton")}
                                            </button>
                                        </div>
                                    </form>
                                    <div className={"invalid-feedback text-center " + (this.state.failMessage ? "d-block" : "")}>
                                        {this.state.failMessage}
                                    </div>
                                    <div className={"valid-feedback text-center " + (this.state.successMessage ? "d-block" : "")}>
                                        {this.state.successMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        );
    }
}
