import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import { customStyles } from "../style/table";
import "../style/postback.css";
import Main from "./base/Main";
import redirectNoToken from "../helper/redirectNoToken";
import i18next from "i18next";

export default class Postback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            postbackList: [],
            isLoading: true,
        };
    }
    componentDidMount() {
        let startDate = moment(Date.now() - 864000000).startOf("date").unix() * 1000;
        this.signal = axios.CancelToken.source();
        document.title = "Postback";
        redirectNoToken.bind(this)();
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/postbacks?from_date=${startDate}&to_date=${Date.now()}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                this.setState({
                    postbackList: response.data.map((v, i) => ({
                        order: v.order_id,
                        offer: v.offer.name,
                        url: v.url,
                        status: v.status,
                        date: new Date(v.created_date).toLocaleString("en-US", { timeZone: "Asia/Ho_Chi_Minh" }),
                    })),
                    isLoading: false,
                });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        const columns = [
            {
                name: i18next.t("order"),
                selector: "order",
                maxWidth: "50px",
                minWidth: "20px",
                sortable: true,
                center: true,
            },
            {
                name: i18next.t("offerName"),
                selector: "offer",
                maxWidth: "200px",
                sortable: true,
            },
            {
                name: "Url",
                selector: "url",
                sortable: true,
                wrap: true,
                minWidth: "400px",
            },
            {
                name: i18next.t("status"),
                selector: "status",
                sortable: true,
            },
            {
                name: i18next.t("modified_date"),
                selector: "date",
                sortable: true,
                wrap: true,
            },
        ];
        return (
            <Main history={this.props.history} linkTo={["/postback"]} linkName={[i18next.t("postback")]} selectedSection={4}>
                <div className="col-12 Postback">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">{i18next.t("postbackLog")}</h2>
                            <button className="btn button-devas" onClick={() => this.props.history.push("/postback/create")}>
                                {i18next.t("createPostback")}
                            </button>
                        </div>
                        <div className="mt-3">
                            <DataTable
                                columns={columns}
                                data={this.state.postbackList}
                                pagination
                                theme="myTheme"
                                className="table"
                                customStyles={customStyles}
                                noHeader={true}
                                progressPending={this.state.isLoading}
                                highlightOnHover={true}
                                defaultSortField="order"
                                defaultSortAsc={false}
                                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                            />
                        </div>
                    </div>
                </div>
            </Main>
        );
    }
}
