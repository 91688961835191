import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/table";
import i18next from "i18next";

export default class ParkingDomain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            domain: "",
            domainList: [],
            validMsg: "",
            invalidMsg: "",
            isTableLoading: true,
        };
    }
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        this.getDomain();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getDomain = () => {
        axios
            .get(`${process.env.REACT_APP_PUB_URL}/domains`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                this.setState({
                    domainList: response.data.map((v, i) => ({
                        id: i + 1,
                        domain: v.domainName,
                        delete: (
                            <button className="btn button-devas-medium" onClick={() => this.deleteDomain(v._id)}>
                                {i18next.t("delete")}
                            </button>
                        ),
                    })),
                    isTableLoading: false,
                });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    deleteDomain = (id) => {
        axios
            .delete(`${process.env.REACT_APP_PUB_URL}/domains/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_pub"),
                },
            })
            .then((response) => {
                this.setState({ domain: "", validMsg: "Delete domain successfully!" });
                this.getDomain();
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    createDomain = () => {
        axios
            .post(
                `${process.env.REACT_APP_PUB_URL}/domains`,
                { domainName: this.state.domain },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_pub"),
                    },
                }
            )
            .then((response) => {
                this.setState({ domain: "", validMsg: "Added domain successfully!" });
                this.getDomain();
            })
            .catch((error) => {
                console.log(error.response.data);
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, invalidMsg: "", validMsg: "" });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.createDomain();
    };
    render() {
        const columns = [
            {
                name: "#",
                selector: "id",
                maxWidth: "50px",
                minWidth: "20px",
                sortable: true,
            },
            {
                name: i18next.t("domainName"),
                selector: "domain",
                minWidth: "250px",
            },
            {
                name: i18next.t("delete"),
                selector: "delete",
                center: true,
            },
        ];
        var { domain, domainList, invalidMsg, validMsg } = this.state;
        var inputClass = "";
        if (invalidMsg !== "") {
            inputClass = "is-invalid";
        }
        if (validMsg !== "") {
            inputClass = "is-valid";
        }
        return (
            <div className="col-12 Tools">
                <div className="py-3 bg-menu mb-1">
                    <div className="px-4">
                        <h2 className="menu-h2-title">{i18next.t("parkingDomain")}</h2>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row px-4 pt-3">
                            <div className="col-9 px-1 px-sm-3 col-xl-8">
                                <input
                                    type="text"
                                    className={`form-control search-input ${inputClass}`}
                                    name="domain"
                                    value={domain}
                                    onChange={this.handleChange}
                                    placeholder="E.g: yourdomain.com"
                                />
                                <div className="invalid-feedback mt-2">{invalidMsg}</div>
                                <div className="valid-feedback mt-2">{validMsg}</div>
                            </div>
                            <div className="col-3 py-1 py-sm-0 px-2 px-sm-0">
                                <button className="btn button-devas-large">{i18next.t("save")}</button>
                            </div>
                        </div>
                    </form>
                    <div className="row px-0 px-sm-4 pt-3">
                        <div className="col-12 col-xl-8">
                            <DataTable
                                columns={columns}
                                data={domainList}
                                pagination
                                theme="myTheme"
                                className="table"
                                customStyles={customStyles}
                                noHeader={true}
                                progressPending={this.state.isTableLoading}
                                highlightOnHover={true}
                                paginationRowsPerPageOptions={[10,20,50,100,200,500,1000]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
